import React from 'react';
import styled from 'styled-components';
import { Editor } from 'tinymce';

import { genericApiError, useFetchApi, usePostApi } from '../useDataApiV2';
import { useTokens } from '../SamState';
import SamGrid, { Cell } from './SamGridV3';
import MasterPage from '../../components/MasterPage';
import useFormMgr from '../forms/useFormMgr';
import SamForm from '../forms/SamFormV4';
import { ButtonsRow, replaceAll } from '../libSupport';
import IconButton from '../IconButton';
import SamTextEditor, { removeAllPTags } from '../dashboard/SamTextEditor';

import { DivisionEnum, FormComboFieldRecord, FormFieldRecord, FormFieldType } from '../../interfaces/lib-api-interfaces';
import { WSCategoryRecord } from '../../interfaces/ws-api-interfaces';

import api from '../../api-url';
import { ContextMenuItem } from '../SamContextMenu';
import ProductEditor from './WSProductEditor';

interface WSCategoryEditorProps {
    division: DivisionEnum;
}
const WSCategoryEditor: React.FC<WSCategoryEditorProps> = (props) => {
    const [categories, setCategories] = React.useState<WSCategoryRecord[]>();
    const [editingCategory, setEditingCategory] = React.useState<string>();
    const [editingProducts, setEditingProducts] = React.useState<string>();

    const { fetch } = useFetchApi();
    const { getToken } = useTokens();

    React.useEffect(() => {
        fetch(api.getProductCategories, getToken()!.token, result => setCategories(result), () => alert(genericApiError));
    }, []);


    const fields: FormFieldRecord[] = [
        { name: "category", label: "Code" },
        { name: "long_desc", label: "Description" },
        { name: "short_desc", label: "Short desc" },
        { name: "product_copy", label: "Copy", fixedWidth: 120 },
        { name: "bullet_points", label: "Bullet points", fixedWidth: 120 },
        { name: "prepack_desc", label: "Prepack descripion", fixedWidth: 120 },
        { name: "prepack_price", label: "Prepack price", type: FormFieldType.money },
        { name: "price", label: "Price", type: FormFieldType.money },
        { name: "discounted_price", label: "Disc price", type: FormFieldType.money },
        { name: "display_item_no", label: "Display item" },
        { name: "display_free_qty", label: "Free display", type: FormFieldType.int },
        { name: "ferns_garden_url", label: "fg.com URL", fixedWidth: 120 },
        { name: "thumb_size", label: "Thumb size", type: FormFieldType.int },
        { name: "thumb_dimension", label: "Thumb dim" },
        { name: "image_size", label: "Img size", type: FormFieldType.int },
        { name: "image_dimension", label: "Img dim" },
    ];
    const gridFormatter = (dataRow: Record<string, any>, name: string, value: any): string | null => {
        //   console.log({name, value})
        //  console.log(fields.find(field => field.name === name))
        if (value && fields.find(field => field.name === name)!.fixedWidth) {
            return value.substring(0, 20) + "...";
        }
        return null;
    }
    const contextMenuInvoked = (cell: Cell): ContextMenuItem[] => {
        return [
            new ContextMenuItem("Edit category", editCategoryCmd, cell.dataRow.category),
            new ContextMenuItem("Edit products", editProductsCmd, cell.dataRow.category)
        ];
    }
    const editCategoryCmd = (userData: string) => {
        setEditingCategory(userData);
    }
    const editProductsCmd = (userData: string) => {
        setEditingProducts(userData);
    }

    if (editingCategory) {
        return (
            <MasterPage>
                <CategoryEditor categoryRecord={categories!.find(category => category.category === editingCategory)!} onClose={() => window.location.reload()} />
            </MasterPage>
        )
    } else if (editingProducts) {
        return (
            <MasterPage>
                <ProductEditor categoryRecord={categories!.find(category => category.category === editingProducts)!} onClose={() => window.location.reload()} />
            </MasterPage>
        )
    } else if (categories) {
        return (
            <MasterPage>
                <SamGrid styles={{ fontSize: "12px" }} columnDefs={fields} dataSource={categories} marginLeft={8} gridStyles={{ rowHeight: 30 }} allowEditing={false} 
                    allowInsert={false} isControlled={true}
                    contextMenuInvoked={contextMenuInvoked} customFormatter={gridFormatter} />
            </MasterPage>
        )
    } else {
        return null;
    }
}
//--------------------------------------------
const MasterContainer = styled.div`
    display: flex; 
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
`
const CategoryEditorTextContainer = styled.div`
    display: flex;
    padding: 16px;
`
// 3 fields: product_copy, bullet_points, prepack_desc
const CategoryText = styled.div`
    width: 19%;
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    margin-right: 4px;
`
interface CategoryEditorProps {
    categoryRecord: WSCategoryRecord;
    onClose: () => void;
}
const CategoryEditor: React.FC<CategoryEditorProps> = (props) => {
    const [copyEditor, setCopyEditor] = React.useState<Editor>();
    const [bpEditor, setBpEditor] = React.useState<Editor>();
    const [prepackEditor, setPrepackEditor] = React.useState<Editor>();
    const [prepackCopyEditor, setPrepackCopyEditor] = React.useState<Editor>();
    const [displayEditor, setDisplayEditor] = React.useState<Editor>();

    const forms = useFormMgr();
    const { post } = usePostApi();
    const { getToken } = useTokens();

    const formId = "categoryForm";

    const numWidth = 80;
    const comboSource: FormComboFieldRecord[] = [
        { caption: "width", value: 'w' },
        { caption: "height", value: 'h' }
    ];

    // form goes above text areas
    const fields: FormFieldRecord[] = [
        { name: "long_desc", label: "Description (max 50 chars)", fixedWidth: 250, validator: { maxLength: 50 } },
        { name: "short_desc", label: "Short description (max 25 chars)", fixedWidth: 200, validator: { maxLength: 25 } },
        { name: "prepack_price", label: "Prepack price", fixedWidth: numWidth, type: FormFieldType.money },
        { name: "price", label: "Price", fixedWidth: numWidth, type: FormFieldType.money },
        { name: "discounted_price", label: "5% off", fixedWidth: numWidth, type: FormFieldType.money },
        { name: "display_item_no", label: "Display item no.", fixedWidth: 100, validator: { maxLength: 4 } },
        { name: "display_free_qty", label: "Free display qty", fixedWidth: 100, type: FormFieldType.int },
        { name: "ferns_garden_url", label: "fernsgarden.com URL", fixedWidth: 400, validator: { maxLength: 100 } },
        { name: "thumb_size", label: "Thumb size", fixedWidth: numWidth, type: FormFieldType.int },
        { name: "thumb_dimension", label: "Thumb dimension", fixedWidth: numWidth*2, type: FormFieldType.combo, comboSource },
        { name: "image_size", label: "Image size", fixedWidth: numWidth, type: FormFieldType.int },
        { name: "image_dimension", label: "Image dimension", fixedWidth: numWidth*2, type: FormFieldType.combo, comboSource },
    ];

    const initialValues = {
        long_desc: props.categoryRecord.long_desc,
        short_desc: props.categoryRecord.short_desc,
        prepack_price: props.categoryRecord.prepack_price,
        price: props.categoryRecord.price,
        discounted_price: props.categoryRecord.discounted_price,
        display_item_no: props.categoryRecord.display_item_no,
        display_free_qty: props.categoryRecord.display_free_qty,
        ferns_garden_url: props.categoryRecord.ferns_garden_url,
        thumb_size: props.categoryRecord.thumb_size,
        thumb_dimension: props.categoryRecord.thumb_dimension,
        image_size: props.categoryRecord.image_size,
        image_dimension: props.categoryRecord.image_dimension
    };

    const formatPrepack = (): string => {
        if (props.categoryRecord.prepack_desc) {
            return "<p>" + replaceAll(props.categoryRecord.prepack_desc, "@", "</p><p>") + "</p>";
        }
        return '';
    }
    const deformatPrepack = (text: string): string => {
        if (text) {
            return replaceAll(replaceAll(text.substring(3, text.length - 4), "\n", ''), "</p><p>", "@");
        }
        return '';
    }

    const saveSuccess = () => {
        alert("Category saved");
        props.onClose();
    }

    const saveClicked = () => {
        const data = forms.getFormValues(formId) as WSCategoryRecord;
        data.product_copy = removeAllPTags(copyEditor!.getContent());
        data.prepack_copy = removeAllPTags(prepackCopyEditor!.getContent());
        data.display_copy = removeAllPTags(displayEditor!.getContent());
        data.bullet_points = removeAllPTags(bpEditor!.getContent());
        data.prepack_desc = deformatPrepack(prepackEditor!.getContent());
        data.category = props.categoryRecord.category;
        data.division = props.categoryRecord.division;
        data.display_order = props.categoryRecord.display_order;
        // console.log(data)
        post(api.setProductCategory, data, saveSuccess, () => alert(genericApiError), getToken()!.token);
    }
    const cancelClicked = () => {
        props.onClose();
    }
    return (
        <MasterContainer>
            <ButtonsRow>
                <IconButton caption="Save all changes" icon="fas fa-check" onClick={saveClicked} />
                <IconButton marginLeft={16} caption="Cancel all changes" icon="fas fa-ban" onClick={cancelClicked} />
            </ButtonsRow>
            <SamForm forms={forms} fields={fields} id={formId} initialValues={initialValues} />
            <CategoryEditorTextContainer>
                <CategoryText>
                    <h2>Product Copy:</h2>
                    <SamTextEditor html={props.categoryRecord.product_copy} textHeight={500} setEditor={editor => setCopyEditor(editor)} />
                </CategoryText>
                <CategoryText>
                    <h2>Bullet Points:</h2>
                    <SamTextEditor html={props.categoryRecord.bullet_points} textHeight={500} removePTags={true} setEditor={editor => setBpEditor(editor)} />
                </CategoryText>
                <CategoryText>
                    <h2>Prepack Contents:</h2>
                    <SamTextEditor id="prepack" html={formatPrepack()} textHeight={500} removePTags={true} setEditor={editor => setPrepackEditor(editor)} />
                </CategoryText>
                <CategoryText>
                    <h2>Prepack Copy:</h2>
                    <SamTextEditor html={props.categoryRecord.prepack_copy} textHeight={500} setEditor={editor => setPrepackCopyEditor(editor)} />
                </CategoryText>
                <CategoryText>
                    <h2>Display Copy:</h2>
                    <SamTextEditor html={props.categoryRecord.display_copy} textHeight={500} setEditor={editor => setDisplayEditor(editor)} />
                </CategoryText>
            </CategoryEditorTextContainer>
        </MasterContainer>
    )
}
export default WSCategoryEditor;