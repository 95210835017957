import React from 'react';

import MasterPage from './MasterPage';
import InfoContentEditor from '../libs/dashboard/InfoContentEditor';

import { DivisionEnum } from '../interfaces/lib-api-interfaces';

const Home: React.FC = () => {
    return (
        <MasterPage>
            <h1>Home Page</h1>
            <InfoContentEditor division={DivisionEnum.wholesale} url="about-jmc" allowVideos={true} />
        </MasterPage>
    )
}
export default Home;
