import React from 'react';
import styled from 'styled-components';
import { Editor } from 'tinymce';
import deepcopy from 'deepcopy';

import SamTextEditor, { assembleTextAndImages, disassembleTextAndImages } from './SamTextEditor';
import ImageListHandler, { AttributesPosition, CaptionFormatEnum, CaptionOptionsEnum, ImageAttributesEditOptions } from './ImageListHandler';
import { genericApiError, useFetchApi, usePostApi } from '../useDataApiV2';
import { ButtonsRow } from '../libSupport';
import IconButton from '../IconButton';
import { useTokens } from '../SamState';
import useFormMgr from '../forms/useFormMgr';
import SamForm from '../forms/SamFormV4';
import InfoContent from '../InfoContent';

import { DivisionEnum, FormFieldRecord, GraphicDimensionType, ImageRecord, InfoContentRecord, InfoIndexRecord, InfoSectionTypeEnum, TextOrImageRecord } from '../../interfaces/lib-api-interfaces';

import app from "../../appData";
import api from "../../api-url";

const MasterContainer = styled.div`
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    p {
        text-align: left;
    }
`
const InfoContentContainer = styled.div`
    margin-bottom: 16px;
`
const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
`
export type SetContentCallbackType = () => InfoContentRecord;

const tempHtml = `
<p>
Fern Solomon wants to make you smile. Her main goal in the creation of these unique tiaras and crowns is to encourage people to celebrate
this moment and delight in the present. Fern is the co-founder of Jacob's Musical Chimes, which for 30 years has been making finely tuned chimes.
She is also the owner of <a href="https://www.fernsgarden.com"><u>Fern's Garden</u></a>, the award winning Long Beach and Berkeley, California,
gift stores featuring the work of American and fair traded artisans.
</p>
<p>
Over the years, Fern has made a hobby of creating paper tiaras from images that appealed to her whimsical sense of design.
She would cut and paste to combine them in novel ways, hand cut the tiaras into themed shapes, and give them to the guests of
honor at birthday parties and special events.
</p>
<p>
After the Portland Gift Show, Fern took a walk on Cannon Beach and she thought... why not create a line of paper tiaras and crowns,
and share the delighted smiles that she was accustomed to seeing at her own family parties? Since the tiaras were born out of celebration,
it was clear that the theme of this company would be to celebrate the moment. Thus Heart the Moment was born.
</p>
<p>
Within months, the first prototype tiaras were being test marketed at Fern's Garden, and upon seeing the smiles of delight on the faces of
her customers, she knew she had a winner. The tiaras and crowns were rolled out to the wholesale marketplace starting in January 2014 at the
AmericasMart Gift Show in Atlanta, where the response was overwhelming. At the National Stationery Show the following May,
the &ldquo;<a href"/products/tiaras/Q-QUS"><u>Queen of this Day</u></a>&rdquo; tiara was nominated as best new product of the year.
</p>
<p>
Fern is thrilled to be creating a product that makes so many people happy, encourages our &ldquo;inner child,&rdquo; and allows us to live in
and appreciate every moment. Please delight in the moment with these unique tiaras and crowns. Enjoy!
</p>
`

interface InfoContentEditorProps {
    division: DivisionEnum;     // fg or wholesale
    url: string;
    defaultSection?: InfoSectionTypeEnum;       // for creating records only; defaults to info
    defaultGraphicsSubfolder?: string;           // ditto; defaults to "other"
    allowVideos?: boolean;
}
const InfoContentEditor: React.FC<InfoContentEditorProps> = (props) => {
    const [url, setUrl] = React.useState<string>();
    const [editors, setEditors] = React.useState([] as Editor[]);
    const [indexRecords, setIndexRecords] = React.useState([] as InfoIndexRecord[]);
    const [html, setHtml] = React.useState([] as string[]);
    const [showPreview, setShowPreview] = React.useState(false);

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const forms = useFormMgr();

    const formId = "info";
    //-------------------------------------------------------------------
    React.useEffect(() => {
        if (props.url !== url) {
            setIndexRecords([]);
            setEditors([]);
            forms.clear();
            setUrl(props.url);
            const textRecords = [] as string[];
            
            fetch(api.getInfoContent + props.division + "/" + props.url, null, (result: InfoContentRecord[]) => {
                //          fetch(api.getLegacyInfoContent + props.division + "/" + props.url, null, (result: {indexRecord: InfoIndexRecord; text: {text: string}}[]) => {
                //               console.log("result:"); console.log(result);
                const newIndexRecords = [] as InfoIndexRecord[];
                if (result.length === 0) {
                    // nothing found so create new content
                    newIndexRecords.push({
                        division: props.division,
                        info_id: 0,
                        section: props.defaultSection ? props.defaultSection : InfoSectionTypeEnum.info,
                        url: props.url,
                        graphics_subfolder: props.defaultGraphicsSubfolder ? props.defaultGraphicsSubfolder : "other",
                        title: '', display_order: 0,     // api will set order to end of list
                        summary: ''
                    });
                    textRecords.push('');
                } else {
                    result.forEach(record => {
                        newIndexRecords.push(record.indexRecord);
                        textRecords.push(assembleTextAndImages(record.contents, record.indexRecord.graphics_subfolder));
                        //            textRecords.push(record.text.text);
                    });
                }
                setIndexRecords(newIndexRecords);
                //     console.log("html:", textRecords)
                setHtml(textRecords);
              //   setHtml([tempHtml]);
            }, () => alert(genericApiError));
        }
    });
    React.useEffect(() => {
        setUrl(props.url);
    }, []);

    const loadEditor = (editor: Editor) => {
        setEditors((state: Editor[]) => {
            state.push(editor);
            return state;
        });
    }

    const logoSubmitted = (images: ImageRecord[], id: string) => {
        //     console.log("setting logo image to:", images[0])
        const newIndexRecords = deepcopy(indexRecords) as InfoIndexRecord[];
        newIndexRecords[parseInt(id)].image = { ...images[images.length - 1] };
        setIndexRecords(newIndexRecords);
    }

    const saveClicked = () => {
        //   console.log("existing info records:", newInfoRecords);
        const contentRecords = collectData();
        //      console.log("post: ", contentRecords)
        post(api.setInfoContent, contentRecords, () => alert("Text saved"), () => alert(genericApiError), getToken()!.token);
    }
    const collectData = (): InfoContentRecord[] => {
        const newInfoRecords = deepcopy(indexRecords) as InfoIndexRecord[];
        const contentRecords = [] as InfoContentRecord[];
        newInfoRecords.forEach((record, index) => {
            const values = forms.getFormValues(formId + index);
            record.division = props.division;
            record.url = values.url;
            record.title = values.title;
            record.subtitle = values.subtitle;
            console.log("record " + index + " text:", editors![index].getContent())
            contentRecords.push({ indexRecord: record, contents: disassembleTextAndImages(editors![index].getContent()) });
        });
        return contentRecords;
    }
    const cancelClicked = () => {
        window.location.reload();
    }
    const fields: FormFieldRecord[] = [
        { name: "url", label: "Url", fixedWidth: 150, validator: { required: true } },
        { name: "title", label: "Title", fixedWidth: 350, validator: { required: true } },
        { name: "subtitle", label: "Subtitle", fixedWidth: 350 }
    ];
    const logoEditOptions: ImageAttributesEditOptions = { captions: CaptionOptionsEnum.allow, captionFormat: CaptionFormatEnum.html, allowResize: true, allowFloat: true };
    return (
        <MasterContainer>
            <React.Fragment>
                <ButtonsRow height={80}>
                    <IconButton style={{ height: "40px" }} caption="Save all changes" icon="fas fa-check" onClick={saveClicked} />
                    <IconButton style={{ height: "40px", marginLeft: "16px" }} caption="Cancel all changes" icon="fas fa-ban" onClick={cancelClicked} />
                    {!showPreview &&
                        <IconButton style={{ height: "40px", marginLeft: "16px" }} caption="Preview" icon="fas fa-eye" onClick={() => setShowPreview(true)} />
                    }
                </ButtonsRow>
                {showPreview &&
                    <PreviewContainer>
                        <ButtonsRow>
                            <IconButton caption="Close preview" icon="fas fa-times" onClick={() => setShowPreview(false)} />
                        </ButtonsRow>
                        <InfoContent data={collectData()} />
                    </PreviewContainer>
                }
                {indexRecords.length &&
                    <React.Fragment>
                        {indexRecords.map((indexRecord, index) => {
                            const logoSize = indexRecord.image ? indexRecord.image.size : 400;
                            return (
                                <InfoContentContainer key={indexRecord.info_id}>
                                    <h2>{indexRecord.title}</h2>
                                    <SamForm id={formId + index} forms={forms} fields={fields}
                                        initialValues={{ url: indexRecord.url, title: indexRecord.title, subtitle: indexRecord.subtitle }} />
                                    <LogoContainer>
                                        <label style={{ marginBottom: "4px", fontWeight: "bold" }}>Logo:</label><br />
                                        <ImageListHandler
                                            id={index + ''} images={indexRecord.image ? [indexRecord.image] : []} 
                                            imageBoxWidth={logoSize} direction="row" allowAddNewVideo={false} attributesPosition={AttributesPosition.bottom} size={logoSize}
                                            onChange={logoSubmitted}
                                            editOptions={{ captions: CaptionOptionsEnum.disallow, allowResize: true, allowFloat: true }}
                                            uploadOptions={{ targetDomain: app.targetDomain, uploadImageApiUrl: api.uploadImage }}
                                            fileOptions={{ graphicsSubfolder: indexRecord.graphics_subfolder, size: logoSize, dimension: GraphicDimensionType.width }}
                                        />
                                    </LogoContainer>
                                    <SamTextEditor 
                                        html={html[index]} 
                                        imageEditOptions={{ captions: CaptionOptionsEnum.allow, captionFormat: CaptionFormatEnum.html, allowResize: true, allowFloat: false }} 
                                        imageUploadOptions={{ targetDomain: app.targetDomain, uploadImageApiUrl: api.uploadImage }}
                                        imageFileOptions={{ graphicsSubfolder: indexRecord.graphics_subfolder, size: 600, dimension: GraphicDimensionType.width }}
                                        allowVideos={props.allowVideos}
                                        textWidth={900} textHeight={700}
                                        setEditor={editor => loadEditor(editor)} 
                                    />
                                </InfoContentContainer>
                            )
                        })}
                    </React.Fragment>
                }
            </React.Fragment>
        </MasterContainer>
    );
}
const PreviewContainer = styled.div`
    border: 2px solid;
`
interface PreviewInfoContentProps {
    contentRecords: InfoContentRecord[];
    onClose: () => void;
}
const PreviewInfoContent: React.FC<PreviewInfoContentProps> = (props) => {
    return (
        <PreviewContainer>
            <ButtonsRow>
                <IconButton caption="Close preview" onClick={props.onClose} />
            </ButtonsRow>
            <InfoContent data={props.contentRecords} />
        </PreviewContainer>
    )
}
//-----------------------------------------
/*
const InfoContentContainer = styled.div`
`
const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
`
const useInfoContentSectionEditor = () => {
    const [editors, setEditors] = React.useState([] as Editor[]);
    const [indexRecords, setIndexRecords] = React.useState([] as InfoIndexRecord[]);
    const [html, setHtml] = React.useState([] as string[]);

    const forms = useFormMgr();

    const formId = "info";
    const fields: FormFieldRecord[] = [
        { name: "url", label: "Url", fixedWidth: 150, validator: { required: true } },
        { name: "title", label: "Title", fixedWidth: 350, validator: { required: true } },
        { name: "subtitle", label: "Subtitle", fixedWidth: 350 }
    ];

    const setContent = (infoContent: InfoContentRecord[]) => {
        console.log("setContent:", infoContent)
        setEditors([]);
        const records = [] as InfoIndexRecord[];
        const textRecords = [] as string[];
        infoContent.forEach((record, index) => {
            records.push(record.indexRecord);
            textRecords.push(assembleTextAndImages(record.contents, record.indexRecord.graphics_subfolder));
            const initialValues = { url: record.indexRecord.url, title: record.indexRecord.title, subtitle: record.indexRecord.subtitle };
            console.log("useInfoContentSectionEditor is calling forms.setFormFieldsAndValues")
            forms.setFormFieldsAndValues(formId + index, initFields(fields), initialValues);
        });
        setIndexRecords(records);
        setHtml(textRecords);
    }
    const loadEditor = (editor: Editor) => {
        setEditors((state: Editor[]) => {
            state.push(editor);
            return state;
        });
    }
    const getContent = (): InfoContentRecord[] => {
        const infoRecords = [] as InfoContentRecord[];
        indexRecords.forEach((record, index) => {
            const values = forms.getFormValues(formId + index);
            const contentRecord = { indexRecord: { ...record, url: values.url, title: values.title, subtitle: values.subtitle } } as InfoContentRecord;
            contentRecord.contents = disassembleTextAndImages(editors![index].getContent());
            infoRecords.push(contentRecord);
        })
        return infoRecords;
    }

    const logoSubmitted = (images: ImageRecord[], id: string) => {
        //     console.log("setting logo image to:", images[0])
        const newIndexRecords = deepcopy(indexRecords) as InfoIndexRecord[];
        newIndexRecords[parseInt(id)].image = { ...images[images.length - 1] };
        setIndexRecords(newIndexRecords);
    }

    const logoEditOptions: ImageAttributesEditOptions = { captions: CaptionOptionsEnum.allow, captionFormat: CaptionFormatEnum.html, allowResize: true, allowFloat: true };

    // returns null if getContent not called yet
    const renderEditors = () => {
        if (!indexRecords.length) return null;
        return (
            indexRecords.map((indexRecord, index) => {
                const logoSize = indexRecord.image ? indexRecord.image.size : 400;
                return (
                    <InfoContentContainer key={indexRecord.info_id}>
                        <SamForm id={formId + index} forms={forms} fields={fields} />
                        <LogoContainer>
                            <label style={{ marginBottom: "4px", fontWeight: "bold" }}>Logo:</label><br />
                            <ImageListHandler
                                id={index + ''} images={indexRecord.image ? [indexRecord.image] : []} imageBoxWidth={logoSize}
                                editOptions={logoEditOptions}
                                graphicsSubfolder={indexRecord.graphics_subfolder} allowAddNewImage={true} direction={"row"} size={logoSize}
                                storedDimension={GraphicDimensionType.width} storedSize={logoSize}
                                targetDomain={app.targetDomain} uploadImageApiUrl={api.uploadImage} outputMagnified={false}
                                onChange={logoSubmitted}
                            />
                        </LogoContainer>
                        <SamTextEditor html={html[index]} allowImages={true}
                            graphicsSubfolder={indexRecord.graphics_subfolder} defaultImageSize={600} textWidth={900} textHeight={700}
                            targetDomain={app.targetDomain} uploadImageApiUrl={api.uploadImage} outputMagnified={false} setEditor={editor => loadEditor(editor)} />
                    </InfoContentContainer>
                )
            })
        )
    }
    return {
        setContent, getContent, renderEditors
    }
}
*/
export default InfoContentEditor;
