import { MenuItemData, DivisionEnum } from './interfaces/lib-api-interfaces';
import { ThemesRecord } from './interfaces/lib-react-interfaces';

const menuItems = [
    new MenuItemData("Home", "/home"),
    new MenuItemData("Products", "/products"),
    new MenuItemData("Search maintenance", "/search-maint"),
];

const isDashboard = true;
const domain = "dashboard.jacobschimes";
const targetDomain = "jacobschimes.com"; 
const serverIp = "https://" + domain + ".com";
const logoUrl = "jmc-dashboard-logo.jpg";
const division = DivisionEnum.jmc;
const maxThumbnailSize = 500;

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
const sansFonts = "Roboto,Arial,Helvetica,sans-serif";
const themes: ThemesRecord = {
    serifFonts: "Volkhov,'Times New Roman',Times,George,Palatino,Garamond",
    sansFonts: sansFonts,
    h1FontFamily: sansFonts,
    foreColor: "#4a5929", // Pantone 574,
    backColor50: "#d3d584",  //  583 at 50%
    backColor25: "#e9ecc2",  //  583 at 25%
    backColor10: "#f6f8e6", // 583 at 10%
    navbarFont: sansFonts,
    defaultFonts: sansFonts
}

export default {
    isDashboard,
    menuItems,
    division,
    domain,
    targetDomain,
    maxThumbnailSize,
    serverIp,
    logoUrl,
    themes
}

