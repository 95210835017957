import React from 'react';
import styled from 'styled-components';

import { genericApiError, useFetchApi, usePostApi } from '../useDataApiV2';
import { useTokens } from '../SamState';
import SamGrid, { Cell } from './SamGridV3';
import { ButtonsRow, formatImageUrl, ImageSizeEnum } from '../libSupport';
import IconButton from '../IconButton';
import ImageListHandler, { AttributesPosition, CaptionOptionsEnum, ImageAttributesEditOptions, ImageUploadOptions } from './ImageListHandler';

import { WSCategoryRecord, WSProductRecord } from '../../interfaces/ws-api-interfaces';
import { FormFieldRecord, FormFieldType, ImageFileOptions, ImageRecord } from '../../interfaces/lib-api-interfaces';

import api from '../../api-url';
import app from '../../appData';

interface ProductEditorProps {
    categoryRecord: WSCategoryRecord;
    onClose: () => void;
}
const ProductEditor: React.FC<ProductEditorProps> = (props) => {
    const [products, setProducts] = React.useState<WSProductRecord[]>();
    const [editingImages, setEditingImages] = React.useState<string>();     // item no

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();

    const productsToDataSource = (): Record<string, any>[] => {
        return products!.map(product => {
            const record = { ...product };
            product.altImages.forEach((filename, index) => {
                record["alt" + (index + 1) + "_image"] = filename;
            });
            return record;
        });
    }

    React.useEffect(() => {
        fetch(api.getProducts + props.categoryRecord.category, getToken()!.token, result => setProducts(result), () => alert(genericApiError));
    }, []);

    const gridFormatter = (dataRow: Record<string, any>, name: string, value: any): string | null => {
        //   console.log({name, value})
        //  console.log(fields.find(field => field.name === name))
        if (name.endsWith("image") && value) {
            return formatImageUrl(value as string, { sizeDesignator: ImageSizeEnum.full });
        }
        return null;
    }
    const saveClicked = () => {

    }
    const cancelClicked = () => {
        window.location.reload();
    }
    const gridCellClicked = (cell: Cell) => {
        if (cell.owningColumn.endsWith("image")) {
            setEditingImages(cell.dataRow.item_no);
        }
        return true;
    }
    const applyImageToCategory = (image: ImageRecord) => {
        if (image.file) {
            alert("Please upload image to web first");
            return;
        }
        const newProducts = products!.map(product => {
            const newProduct = { ...product };
            if (!product.altImages.includes(image.filename!)) {
                newProduct.altImages.push(image.filename!);
            }
            return newProduct;
        });
        setProducts(newProducts);
        setEditingImages(undefined);
    }
    const deleteImageFromCategory = (image: ImageRecord) => {
        if (image.file) {
            alert("Please upload image to web first");
            return;
        }
        const newProducts = products!.map(product => {
            const newProduct = { ...product };
            const index = product.altImages.indexOf(image.filename!);
            if (index !== -1) {
                newProduct.altImages.splice(index, 1);
            }
            return newProduct;
        });
        console.log("newProducts:", newProducts)
        setProducts(newProducts);
        setEditingImages(undefined);
    }

    const imageWidth = 70;
    const fields: FormFieldRecord[] = [
        { name: "item_no", label: "Item #" },
        { name: "caption", label: "Caption", allowEditing: true },
        { name: "main_image", label: "Main image", type: FormFieldType.image, fixedWidth: imageWidth },
        { name: "tags", label: "tags", fixedWidth: 200, allowEditing: true },
        { name: "alt1_image", label: "Alt image", type: FormFieldType.image, fixedWidth: imageWidth },
        { name: "alt2_image", label: "Alt image", type: FormFieldType.image, fixedWidth: imageWidth },
        { name: "alt3_image", label: "Alt image", type: FormFieldType.image, fixedWidth: imageWidth },
        { name: "alt4_image", label: "Alt image", type: FormFieldType.image, fixedWidth: imageWidth },
        { name: "alt5_image", label: "Alt image", type: FormFieldType.image, fixedWidth: imageWidth },
    ];

    if (!products) {
        return null;
    }
    if (editingImages) {
        return (
            <React.Fragment>
                <ButtonsRow>
                    <IconButton height={40} caption="Back to products" icon="fas fa-times" onClick={() => setEditingImages(undefined)} />
                </ButtonsRow>
                <WSProductImageEditor product={products.find(product => product.item_no === editingImages)!} categoryRecord={props.categoryRecord}
                    applyImageToCategory={applyImageToCategory} deleteImageFromCategory={deleteImageFromCategory} />
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <ButtonsRow>
                <IconButton height={40} caption="Save all changes" icon="fas fa-check" onClick={saveClicked} />
                <IconButton height={40} marginLeft={16} caption="Cancel all changes" icon="fas fa-ban" onClick={cancelClicked} />
            </ButtonsRow>
            <p>Click on an image to invoke image editor</p>
            <SamGrid dataSource={productsToDataSource()} columnDefs={fields} gridStyles={{ rowHeight: 30 }} allowEditing={true} customFormatter={gridFormatter} cellClicked={gridCellClicked} />
        </React.Fragment>
    )
}
//------------------------------------------------------
const ProductImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
`
const MainOrAltContainer = styled.div`
    border: 1px solid;
    margin-top: 16px;
`
interface WSProductImageEditorProps {
    product: WSProductRecord;
    categoryRecord: WSCategoryRecord;
    applyImageToCategory?: (image: ImageRecord) => void;
    deleteImageFromCategory?: (image: ImageRecord) => void;
}
const WSProductImageEditor: React.FC<WSProductImageEditorProps> = (props) => {
    const [mainImage, setMainImage] = React.useState<ImageRecord[]>();
    const [altImages, setAltImages] = React.useState<ImageRecord[]>([]);

    React.useEffect(() => {
        setMainImage([{ filename: props.product.main_image }]);
        const images = props.product.altImages.map(image => {
            return { filename: image };
        });
        setAltImages(images);
    }, []);

    const mainImageChanged = (newImages: ImageRecord[]) => {
        setMainImage(newImages);
    }
    const altImagesChanged = (newImages: ImageRecord[]) => {
        setAltImages(newImages);
    }
    const imageListButtonClicked = (id: string, image: ImageRecord) => {
        if (id === "apply") {
            props.applyImageToCategory!(image);
        } else if (id === "delete") {
            props.deleteImageFromCategory!(image);
        } else {
            throw "Invalid button ID";
        }
    }

    const editOptions: ImageAttributesEditOptions = { captions: CaptionOptionsEnum.disallow, allowResize: false, allowFloat: false };
    const uploadOptions: ImageUploadOptions = { targetDomain: app.targetDomain, uploadImageApiUrl: api.uploadImage };
    const fileOptions: ImageFileOptions = { sizeDesignator: ImageSizeEnum.full, size: props.categoryRecord.image_size, dimension: props.categoryRecord.image_dimension };
    if (!mainImage) {
        return null;
    }
    return (
        <ProductImagesContainer>
            <MainOrAltContainer>
                <h2>Main image:</h2>
                <ImageListHandler id="main" images={mainImage} imageBoxWidth={300} direction="row" allowAddNewVideo={false} attributesPosition={AttributesPosition.bottom} size={300}
                    onChange={mainImageChanged} editOptions={editOptions} uploadOptions={uploadOptions} fileOptions={fileOptions} />
            </MainOrAltContainer>
            <MainOrAltContainer>
                <h2>Alt images:</h2>
                <ImageListHandler id="alt" images={altImages} imageBoxWidth={300} direction="row" allowAddNewVideo={false} attributesPosition={AttributesPosition.bottom} size={300}
                    customButtons={[{ id: "apply", caption: "Apply to entire category " }, { id: "delete", caption: "Delete from entire category" }]}
                    customButtonClicked={imageListButtonClicked}
                    onChange={altImagesChanged} editOptions={editOptions} uploadOptions={uploadOptions} fileOptions={fileOptions}
                />
            </MainOrAltContainer>
        </ProductImagesContainer>
    )
}
export default ProductEditor;
