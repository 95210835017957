import React from 'react';
import styled from 'styled-components';

import { IconButtonProps } from '../interfaces/lib-react-interfaces';

import app from '../appData';

const StyledButton = styled.button<{ cursor: string }>`
    cursor: ${props => props.cursor};
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        display: inline;
    }
`
export const IconPosition = { left: 0, right: 1 }
const IconButton: React.FC<IconButtonProps> = (props) => {
    const style = props.style ? { ...props.style } : {};
    style.fontSize = style.fontSize ? style.fontSize : "14px";
    style.color = style.color ? style.color : app.themes.foreColor;
    if (props.marginLeft) {
        style.marginLeft = props.marginLeft + "px";
    }
    if (props.height) {
        style.height = props.height + "px";
    }
    style.backgroundColor = style.backgroundColor ? style.backgroundColor : app.themes.backColor25;
    if (props.isDisabled) {
        style.color = "ghostwhite";
        style.backgroundColor = "lightgray";
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!props.isDisabled && props.onClick) {
            props.onClick(e);
        }
    }
    return (
        <StyledButton cursor={props.isDisabled ? "default" : "pointer"} id={props.id} onClick={handleClick}
          style={style}>
            {props.icon ? (
                <Icon {...props} />
            ) : (
                    props.caption
                )}
        </StyledButton>
    );
}
// onClick={props.isDisabled ? null : props.onClick}
interface IconProps {
    iconPosition?: number;
    caption: string;
    icon?: string;      // icon is in face always defined
}
const Icon: React.FC<IconProps> = (props) => {
    return (
        props.iconPosition && props.iconPosition === IconPosition.right ? (
            <React.Fragment>
                {props.caption}&nbsp;
                <i className={props.icon}></i>
            </React.Fragment>
        ) : (
                <React.Fragment>
                    {props.icon!.startsWith("/graphics") || props.icon!.startsWith("http") ? (
                        <img src={props.icon} />
                    ) : (
                        <i className={props.icon}></i>
                        )}
                   &nbsp; {props.caption}
                </React.Fragment>
            )
    );
}
export default IconButton;
