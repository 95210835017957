import React from 'react';
import styled from 'styled-components';
import deepcopy from 'deepcopy';

import IconButton from '../IconButton';
import DatePicker from './DatePicker';
import useFormMgr, { UseFormMgr } from './useFormMgr';
import RifmNumeric from './RifmNumeric';
import { ButtonsRow, getNearestParentId } from '../libSupport';
import SamModal from '../SamModal';

import { IconButtonProps } from '../../interfaces/lib-react-interfaces';
import { FormFieldRecord, FormComboFieldRecord, CustomValidatorCallback, CustomFormatterCallback, FormFieldType } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';

/* import useFormMgr with CornerstoneForm
    at top of component with form:
        const forms = useFormMgr();
    
     form container is set to 100% width and no centering; enclose in another container to set these (or use style object)
     props:
        id: string (unique id required)
        forms: returned from useFormMgr (required)
        initialValues (optional)
        fields: [] -- array of field objects (see below) -- required
        dualColumn: bool -- true to show 2 columns if there is room (overrides width and newRow settings on fields)

        button handling:
            either pass the following:
                submitCaption: caption for submit button
                submitIcon: string -- icon for submit button
                noCancel: bool -- true to suppress cancel button; otherwise it is defaulted
            OR submitButtons: [] -- an array of button params as follows:
                 id: passed to submit()
                 style: {} -- standard React styling
                 caption: passed to IconButton
                 icon: passed to IconButton
                 validate: bool -- true to validate form before calling props.submit
            to omit ALL buttons pass submit=null and noCancel=true

        callbacks:
            submit(values, buttonId) -- buttonId used if buttons were passed in; null on cancel, Record<string, any> on OK; return true to accept, false if invalid
            customValidator(fieldName, values) -- called upon form submit
            customFormatter(fieldName, value) -- return null to use default format
            onChange(values)

        FormFieldType.link: place this on a field to display it as a link rather than a field or button
            label=link text
            if link is clicked, submit is called with id=name
            parent must do their own validation

        field object:
            name: string
            label: string
            type: string (see FormFieldType below)
            inputType: string (see inputTypes below)
            height: int -- applies to textArea only (defaults to 100)
            marginTop: int -- for extra spacing on this row
            button: {} -- place button to right of field (see below)
            validator: validator object (see below)
            comboSource: [] -- displays a dropdown with combo values; array of {caption, value}
            field positioning:
                pass width: x as a width percent to place more than one field on a row
                if width omitted, 100 is assumed
                if fields are stacked on multiple rows, pass newRow: true as needed to start a new row (100% fields automatically get a new row)
                pass fixedWidth: int to fix the width of input in pixels
                marginTop
            callbacks:
            onClick(id): button object only
            hideField(values): return true to hide field; called before the field is rendered; for changing display based on values of certain fields
        
        validator object:
            required: bool
            maxLength: int
            minLength: int

        button object:
            caption: string
            id: string (optional)
            onClick: callback with id (if any)
            onInlineClick: callback is passed value for inline button
            style: optional React style object
*/

//----------------------------------------------------------------
const MasterContainer = styled.div<{ maxWidth: number; fontSize: number }>`
    max-width: ${props => props.maxWidth}px;
    font-size: ${props => props.fontSize}px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 1%;
    padding-right: 1%;
`
// 1 label/input/error box
const InputBox = styled.div<{ widthPct: number; marginTop?: number; maxWidth?: number }>`
    width: ${props => props.widthPct}%;     /* field.width or 100 */
    margin-top: ${props => props.marginTop}px;
    max-width: ${props => props.maxWidth}px;
    display: flex;
    flex-direction: column;     /* rows are: label, input, error */
    justify-content: flex-start;
`
//-----------------
const StyledLabelAndRequiredRow = styled.div<{ fontSize: number; lineHeight: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${props => props.fontSize}px;
    line-height: ${props => props.lineHeight}px;
    color: #666;
`
const LabelText = styled.span`
    margin-bottom: 2px;
`
//-----------------
const StyledInput = styled.input<{ borderColor: string; height: number; width?: number }>`
    border: 1px solid ${props => props.borderColor};     /* #666 or red if error */
    height: ${props => props.height}px;
    width: ${props => props.width}px;
`
const StyledTextArea = styled.textarea<{ borderColor: string; height: number; backColor: string }>`
    border: 2px solid ${props => props.borderColor};     /* #666 or red if error */
    height: ${props => props.height}px;
    background-color: ${props => props.backColor};      /* should be backColor25 */
    border-style: outset;
`
const StyledSelect = styled.select<{ inputHeight: number }>`
    height: ${props => props.inputHeight}px;
    border: 1px solid #ccc;
`
const StyledLinkText = styled.p<{ marginTop?: number }>`
    text-align: left;
    cursor: pointer;
    width: 100%;
    text-decoration: underline;
    margin-top: 0;
    margin-bottom: 0;
    :hover {
        font-style: italic;
    }
`
const StyledHorizontalField = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
`
const CheckboxCaption = styled.span`
    text-align: left;
    margin-left: 8px;
`
//-----------------
// const Buttons = styled.div`
//     display: flex;
//     width: 100%;
//     height: 2.5rem;
//     justify-content: center;
//     margin-top: 16px;
// `
export interface SamFormButton {
    caption: string;
    id: string;
    icon?: string;
    style?: Record<string, any>;
    validate?: boolean;
}
export interface SamFormModalProps {
    fields: FormFieldRecord[];
    fontSize?: number;          // default to 14px
    labelFontSize?: number;     // default to 85% of fontSize
    maxWidth?: number;          // default to 1000
    inputHeight?: number;   // height of input field in pixels (applies to text and numbers only) -- used as default on fields without this prop
    marginTop?: number;    // vertical space between fields -- used as default on fields without this prop
    initialValues?: Record<string, any> | null;
    dualColumn?: boolean;       // sets all widths to 48% unless overridden in fields array
    submitButtons?: SamFormButton[];
    createSubmitButton?: boolean;       // this and next are shortcuts to avoid passing submitButtons; handleSubmit is called with id of "submit"
    createCancelButton?: boolean;       // handleSubmit is called with value null

    onChange?: (values: Record<string, any>) => void;
    handleSubmit?: (values: Record<string, any> | null, id: string) => void;      // shows submit button and returns validated values if passed; id is id of button element (not form)
    customFormatter?: CustomFormatterCallback;
    customValidator?: CustomValidatorCallback;
    linkClicked?: (e: React.MouseEvent<HTMLElement>) => void;
    inlineButtonClicked?: (fieldName: string, value: string) => void;

    backColor?: string;     // applies to modal only
    title?: string;         // modal only
}
export interface SamFormProps extends SamFormModalProps {
    forms: UseFormMgr;
    id: string;
}
const SamForm: React.FC<SamFormProps> = (props) => {
    if (!props.forms || !props.id) {
        throw "useFormMgr and id must be passed to ManagedForm";
    }

  //  console.log("SamForm:", props)

    const id = props.id;
    const maxWidth = props.maxWidth ? props.maxWidth : 1000;
    const fontSize = props.fontSize ? props.fontSize : 14;
    const labelFontSize = props.labelFontSize ? props.labelFontSize : fontSize * .85;
    let buttons: IconButtonProps[] | null = null;

    const setButtonMarginLeft = (button: IconButtonProps, margin: number) => {
        if (button.style) {
            if (!button.style.leftMargin) {
                button.style.marginLeft = "16px";
            }
        } else {
            button.style = { marginLeft: "16px" };
        }
    }

    const initButtons = (): IconButtonProps[] => {
        const submitButtons: IconButtonProps[] = [];
        if (props.submitButtons) {
            props.submitButtons.forEach(b => {
                submitButtons.push({ ...b });
            });
        }
        if (props.createSubmitButton) {
            // we want a default submit button
            if (submitButtons.find(b => b.id === "submit")) {
                throw "SamFormV4: Cannot create submit button; it already exists";
            } else {
                submitButtons.push({ caption: "Submit", id: "submit", icon: "fas fa-check", validate: true });
            }
        }
        if (props.createCancelButton) {
            if (submitButtons.find(b => b.id === "cancel")) {
                throw "SamFormV4: Cannot create cancel button; it already exists";
            } else {
                submitButtons.push({ caption: "Cancel", id: "cancel", icon: "fas fa-ban", validate: false });
            }
        }
        submitButtons.forEach((b: IconButtonProps, index: number) => {
            if (index > 0) {
                setButtonMarginLeft(b, 16);
            }
        });
        return submitButtons;
    }

    if (props.submitButtons || props.createSubmitButton || props.createCancelButton) {
        buttons = initButtons();
    }
    const initFields = (rawFields: FormFieldRecord[], dualColumn?: boolean, marginTop?: number, inputHeight?: number ): FormFieldRecord[] => {
        const fields = deepcopy(rawFields) as FormFieldRecord[];
        fields.forEach(field => {
            if (field.type === FormFieldType.digitsOnly && (!field.validator || !field.validator.maxLength)) {
                throw field.name + ": field.validator.maxLength required on digitsOnly fields";
            }
            if (dualColumn && !field.width) {
                field.width = 48;
            }
            if (!field.type) {
                field.type = FormFieldType.text;
            }
            if (field.visible === undefined) {
                field.visible = true;
            }
            if (field.button) {
                setButtonMarginLeft(field.button, 16);
            }
            field.marginTop = field.marginTop ? field.marginTop : (marginTop ? marginTop : 12);
            field.inputHeight = field.inputHeight ? field.inputHeight : (inputHeight ? inputHeight : 24);
        });
        //     console.log("fields:", fields)
        return fields;
    }
    
    React.useEffect(() => {
        // fields are set once and not checked every render, there will not be a re-render after setting them (at least with multiple forms on page)
        if (!props.forms.getFormFields(id)) {
            props.forms.setFormFieldsAndValues(id, initFields(props.fields, props.dualColumn, props.marginTop, props.inputHeight), props.initialValues ? { ...props.initialValues } : {});
            //      console.log("fields:", props.forms.getFormFields(id));
        }
    });

    const getFormattedValue = (field: FormFieldRecord): string => {
        let value: string = props.forms.getValue(id, field.name);
        if (props.customFormatter) {
            const formatted = props.customFormatter(field.name, value);
            if (formatted) {
                return formatted;
            }
        }
        return value;
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const target = e.target as HTMLInputElement;
        handleChange(target.value, target.id);
    }
    const handleCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as any;
        handleChange(target.checked as boolean, target.id);
    }
    const handleChange = (value: string | number | boolean, name: string) => {
        //       console.log("handleChange on " + name + ", value=" + value)
        const field = props.forms.getField(id, name) as FormFieldRecord;
        if (field.validator) {
            if (field.validator.isAllUpper) {
                value = (value as string).toUpperCase();
            }
            if (field.validator.maxLength) {
                value = (value as string).substring(0, field.validator.maxLength);
            }
        }
        if (props.forms.getValue(id, field.name) !== value) {
            const newValues = props.forms.setValue(id, field.name, value);
            if (props.onChange) {
                props.onChange(newValues);
            }
            props.forms.deleteError(id, field.name);
        }
    }

    // called when any button clicked; could be created or passed in
    const handleButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const target = getNearestParentId(e.target as HTMLElement);
        const button = buttons!.find(b => target.id === b.id) as IconButtonProps;
        if (button.validate && !props.forms.validateForm(id, props.customValidator)) {
            return;
        }
        // at this point form is valid or button is a no-validate button
        const values = target.id === "cancel" ? null : props.forms.getFormValues(id);
        props.handleSubmit && props.handleSubmit(values, target.id);
    }
    const inlineButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const field = props.forms.getField(props.id, target.id) as FormFieldRecord;
        let value = props.forms.getValue(props.id, field.name);
        props.inlineButtonClicked!(field.name, value);
    }
    if (!props.forms.getFormFields(id)) {
        return null;
    }

    const isHorizontalField = (fieldType: FormFieldType): boolean => {
        const horizontalFields: FormFieldType[] = [FormFieldType.link, FormFieldType.checkbox, FormFieldType.button];
        return horizontalFields.includes(fieldType);
    }

    const renderInputField = (field: FormFieldRecord, isError: boolean): any => {
        switch (field.type) {
            case FormFieldType.text:
                return <StyledInput id={field.name} borderColor={isError ? "red" : "#ccc"} width={field.fixedWidth ? field.fixedWidth : undefined}
                    value={getFormattedValue(field)} height={field.inputHeight!} placeholder={field.placeholder} type={field.validator && field.validator.isPassword ? "password" : "text"}
                    onChange={handleInputChange} />
            case FormFieldType.multiLine:
                return <StyledTextArea id={field.name} borderColor={isError ? "red" : "#ccc"}
                    value={getFormattedValue(field)} height={field.inputHeight!} placeholder={field.placeholder}
                    backColor={app.themes.backColor25} onChange={handleInputChange} />
            case FormFieldType.combo:
                return (
                    <StyledSelect id={field.name} inputHeight={field.inputHeight!} value={getFormattedValue(field)}
                        onChange={handleInputChange}>
                        {field.comboSource!.map((option) => {
                            return (
                                <option key={option.value} value={option.value}>{option.caption}</option>
                            );
                        })}
                    </StyledSelect>
                )
            case FormFieldType.link:
                return (
                    <StyledLinkText id={field.name} onClick={props.linkClicked}>{field.label!}</StyledLinkText>
                )
            case FormFieldType.checkbox:
                return (
                    <StyledHorizontalField>
                        <input id={field.name} type="checkbox" value='' checked={props.forms.getValue(id, field.name)} onChange={handleCheckedChanged} />
                        <CheckboxCaption>{field.label}</CheckboxCaption>
                    </StyledHorizontalField>
                )
            case FormFieldType.button:
                return (
                    <StyledHorizontalField>
                        <span>{field.label}&nbsp;</span>
                        <StyledInput id={field.name} borderColor={isError ? "red" : "#ccc"} width={field.fixedWidth}
                            value={getFormattedValue(field)} height={field.inputHeight!} placeholder={field.placeholder}
                            type={field.validator && field.validator.isPassword ? "password" : "text"}
                            onChange={handleInputChange} />
                        {field.button &&
                            <IconButton id={field.name} caption={field.button.caption} style={field.button.style} icon={field.button.icon} onClick={inlineButtonClicked} />
                        }
                    </StyledHorizontalField>
                )
            case FormFieldType.reactPaymentInputs:
                return (
                    <input id={field.name} {...field.getProps!({ onChange: handleInputChange })} value={getFormattedValue(field)} />
                )
            case FormFieldType.date:
                return (
                    <DatePicker name={field.name} value={props.forms.getValue(id, field.name)} onChange={handleChange} />
                )
            case FormFieldType.phone:
                return (
                    <RifmNumeric name={field.name} fieldType={field.type} initialValue={props.forms.getValue(id, field.name)} height={field.inputHeight}
                        borderColor={isError ? "red" : "#ccc"} textAlign={"left"}
                        onChange={handleChange} />
                )
            case FormFieldType.int:
            case FormFieldType.money:
                return (
                    <RifmNumeric name={field.name} fieldType={field.type} initialValue={props.forms.getValue(id, field.name)} borderColor={isError ? "red" : "#ccc"}
                        placeholder={field.placeholder} height={field.inputHeight}
                        onChange={handleChange} />
                )
            case FormFieldType.digitsOnly:
                return (
                    <RifmNumeric name={field.name} fieldType={field.type} initialValue={props.forms.getValue(id, field.name)} borderColor={isError ? "red" : "#ccc"}
                        placeholder={field.placeholder} height={field.inputHeight} textAlign={"left"}
                        onChange={handleChange} />
                )
        }
        return null;
    }
    return (
        <MasterContainer maxWidth={maxWidth} fontSize={fontSize}>
            {props.forms.getFormFields(props.id).map(field => {
                if (field.visible && (!field.hideField || !field.hideField(props.forms.getFormValues(props.id)))) {
                    const error = props.forms.getError(props.id, field.name);
                    return (
                        <InputBox key={field.name} widthPct={field.width ? field.width : 97} marginTop={field.marginTop} maxWidth={field.fixedWidth}>
                            {!isHorizontalField(field.type!) && field.label &&
                                <LabelAndRequiredRow label={field.label} fontSize={labelFontSize} required={!!(field.validator && field.validator.required)} />
                            }
                            {renderInputField(field, !!error)}
                            {error &&
                                <ErrorRow text={error} />
                            }
                        </InputBox>
                    )
                }
            })}
            {buttons &&
                <ButtonsRow>
                    {buttons.map((button) => {
                        return (
                            <IconButton {...button} key={button.id} onClick={handleButtonClicked} />
                        )
                    })}
                </ButtonsRow>
            }
        </MasterContainer>
    )
}

/* LabelAndRequiredRow: common component for inputs where label is above input area
    label: string
    required: bool
*/
interface LabelAndRequiredRowProps {
    label: string;
    required: boolean;
    fontSize: number;
}
const LabelAndRequiredRow: React.FC<LabelAndRequiredRowProps> = (props) => {
    return (
        <StyledLabelAndRequiredRow fontSize={props.fontSize} lineHeight={props.fontSize * 1.2}>
            <LabelText>
                {props.label}
            </LabelText>
            {props.required &&
                <span>
                    REQUIRED
                </span>
            }
        </StyledLabelAndRequiredRow>
    )
}

/* ErrorRow
    text: string (optional; return null if no text)
*/
const ErrorText = styled.span`
    font-size: 1rem;
    color: red;
    line-height: 1.2rem;
    margin-top: 4px;
    text-align: left;
`
interface ErrorRowProps {
    text?: string;
}
const ErrorRow: React.FC<ErrorRowProps> = (props) => {
    if (props.text) {
        return (
            <ErrorText>{"x " + props.text}</ErrorText>
        )
    } else {
        return null;
    }
}

export const selectMonthList: FormComboFieldRecord[] = [
    { caption: "", value: "0" },
    { caption: "01 - January", value: "1" },
    { caption: "02 - February", value: "2" },
    { caption: "03 - March", value: "3" },
    { caption: "04 - April", value: "4" },
    { caption: "05 - May", value: "5" },
    { caption: "06 - June", value: "6" },
    { caption: "07 - July", value: "7" },
    { caption: "08 - August", value: "8" },
    { caption: "09 - September", value: "9" },
    { caption: "10 - October", value: "10" },
    { caption: "11 - November", value: "11" },
    { caption: "12 - December", value: "12" },
];
export const selectYearList = (): FormComboFieldRecord[] => {
    let list = [{ caption: "", value: "0" }];
    let year = new Date().getFullYear();
    for (let yr = year; yr < year + 10; yr++) {
        list.push({ caption: yr.toString(), value: (yr + '').slice(-2) });
    }
    return list;
}

const ModalTitle = styled.p`
    font-weight: bold;
    font-size: 24px;
`
export const SamFormModal: React.FC<SamFormModalProps> = (props) => {
    const forms = useFormMgr();
    return (
        <SamModal component={ModalForm as React.FC} componentProps={{ ...props, forms, id: "modalform" }} />
    )
}
const ModalForm: React.FC<SamFormProps> = (props) => {
    return (
        <React.Fragment>
            {props.title &&
                <ModalTitle>{props.title}</ModalTitle>
            }
            <SamForm {...props} />
        </React.Fragment>
    )
}

export default SamForm;
