import React from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';
import deepcopy from 'deepcopy';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import ContextMenu, { ContextMenuInfo, ContextMenuItem } from '../SamContextMenu';
import { getNearestParentId, measureText } from '../libSupport';

import { FormFieldRecord, FormFieldAlignType, FormFieldType, RowState } from '../../interfaces/lib-api-interfaces';

import '../../App.css';

//import { useSessionStore } from './SamState';

import app from '../../appData';
import RifmNumeric from '../forms/RifmNumeric';

//const columnType = { int: 0, money: 1, text: 2, image: 3, contextMenu: 4, selector: 5, bool: 6 }


// REQUIRES: react-scroll-sync

/* props
    /src/appData.js must contain themes object specifying fontSize, defaultFonts, backColor25, backColor10
    id: string -- required if app has more than one grid; passed to onChange as second param
    dataSource: array of key/value objects
    icControlled: bool -- true if parent is keeping data and props.dataSource is always current; false if props.dataSource is initial data only
    columnDefs: array of column def records
    allowEditing: bool
    allowDelete: bool (added to context menu)
    allowInsert: bool (added to context menu; pass newRow to control default inserted content)
    thumbNailMode : bool -- true to display images as thumbnails, false to show text filename; url is formatted using formatImageUrl in libSupport
    fixedCols: int -- default to 0
    showCaptions: bool -- default to true
    styles: React style object for all styles applied to grid as a whole e.g.:
    {
        fontSize; (overrides app.themes.fontSize; if none given defaults to 14)
        fontFamily; (overrides app.themes.defaultFonts)
        color;
        margin;
    }
    gridStyles: grid-specific styles:
    {
        captionFontSize: int -- defaults to 12
        rowHeight: int -- defaults to fontSize + 12
        captionHeight: int -- defaults to captionFontSize + 12
        maxColWidth: int (defaults to 500)
        minColWidth: int (defaults to 0)
        horizontalCellPadding: int (defaults to 3); vertical is 6
    }

    callbacks:
    onChange(dataSource, id)
    cellClicked(row, col, value)
    getImageInfo(dataRow) -- returns { size: int, dimension: char } if dropping of image files on image fields is supported
    customFormatter(dataRow, colName, value) -- return formatted value or null to use default formatting
    newRow(dataRow): passed empty row, caller can load row with default values; called when user inserts new row
    contextMenuInvoked(Cell object) -- return array of ContextMenuItem objects; callback on click is passed userData object
*/

// holds 2 divs: fixedRowCol+fixedRow and fixedCols+scrollableRows
const GridContainer = styled.div<{ marginLeft: number }>`
    display: flex;
    flex-direction: column;
    margin-left: ${props => props.marginLeft}px;        /* use gridWidth to calculate this so grid is centered */
`
// holds fixedRowCol+fixedRow 
const CaptionContainer = styled.div`
    display: flex;
`
// holds fixedCols+scrollableRows
const NonCaptionContainer = styled.div`
    display: flex;
`
const StyledRow = styled.div`
    display: flex;
    flex-shrink: 0;
`
const StyledGridCell = styled.div<{ width: number; height: number; backColor?: string; justifyContent: string; cursor?: string; horizontalPadding: number }>`
    height: ${props => props.height}px;
    background-color: ${props => props.backColor};      /* if caption or fixed column set to backColor25 */
    min-width: ${props => props.width}px;
    flex-basis: ${props => props.width}px;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent};      /* flex-start, flex-end or center */
    cursor: ${props => props.cursor};
    border: 1px solid;
    white-space: nowrap;
    padding: 6px ${props => props.horizontalPadding}px;
`
const StyledTextEditor = styled.textarea<{ top: number; left: number; width: number; height: number }>`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border: none;
    background-color: yellow;
    z-index: 2300;
`
const StyledNumericEditor = styled.div<{ top: number; left: number; width: number; height: number }>`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border: none;
    background-color: yellow;
    z-index: 2300;
`
// following passed to cellClicked and contextMenuInvoked
export class Cell {
    rowIndex: number;
    colIndex: number;
    dataRow: Record<string, any>;
    owningColumn: string;
    element: HTMLElement;
    constructor(rowIndex: number, colIndex: number, dataRow: Record<string, any>, owningColumn: string, element: HTMLElement) {
        this.rowIndex = rowIndex;
        this.colIndex = colIndex;
        this.dataRow = dataRow;
        this.owningColumn = owningColumn;
        this.element = element;
    }
}

const selectedColor = "aqua";
const selectorWidth = 20;
const ctrlHomeKeycode = 36;

interface GridStylesRecord {
    captionFontSize?: number;   // default to 12
    rowHeight?: number;         // default to fontSize
    showCaptions?: boolean;     // default to true
    captionHeight?: number;     // only if showCaptions true; default to fontSize+12
    horizontalCellPadding?: number; // default to 3
    maxColWidth?: number;       // default to 500
    minColWidth?: number;
}
interface CustomFormatterCallback {
    (row: Record<string, any>, name: string, value: any): string | null;
}
interface SamGridProps {
    dataSource: Record<string, any>[];
    columnDefs: FormFieldRecord[];
    id?: string;
    suppressScrolling?: boolean;    // default to false; scrolling forces grid to full window height which is not good if other elements on page
    marginLeft?: number;        // pass this to override grid's attempt to center based on its width
    allowEditing: boolean;
    allowDelete?: boolean;
    allowInsert?: boolean;
    thumbNailMode?: boolean;
    styles?: Record<string, any>;
    gridStyles?: GridStylesRecord;
    showCaptions?: boolean;             // default to true; overrides gridStyles
    horizontalCellPadding?: number;     // default to 3
    fixedCols?: number;
    isControlled?: boolean;
    customFormatter?: CustomFormatterCallback;
    onChange?: (dataSource: Record<string, any>[], id: string | undefined) => void;
    newRow?: (row: Record<string, any>) => void;     // called when a new row is added
    contextMenuInvoked?: (cell: Cell) => ContextMenuItem[];
    cellClicked?: (cell: Cell) => boolean;      // return false to disallow editing
}
const SamGrid: React.FC<SamGridProps> = (props) => {
    //    console.log("SamGrid props:", props);

    const scrollableRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    // following are for setting scroll position
    const fixedRowsRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const fixedColsRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    // text editor
    const editorRef = React.useRef<HTMLTextAreaElement | HTMLInputElement>() as React.MutableRefObject<HTMLTextAreaElement | HTMLInputElement>;

    const [contextMenuInfo, setContextMenuInfo] = React.useState<ContextMenuInfo | null>(null);     // { location: {x,y}, menu: [contextMenuItem] } (userData = Cell object)
    const [editorCell, setEditorCell] = React.useState<Cell | null>(null);     // info on cell currently being edited : {row, col, element, type} type is columnType.text/
    const [modifiedImages, setModifiedImages] = React.useState([]); // [{ row, col, action : modifiedImageAction, file : FILE }] (FILE required if action is update)
    const [scrollHome, setScrollHome] = React.useState(false);
    const [focusedRow, setFocusedRow] = React.useState(-1);     // if >= 0 useEffect will place focus on first column of this row
    const [sortedColumn, setSortedColumn] = React.useState<{ col: number; sortAscending: boolean }>();   // { col : int, sortAscending : bool }
    const [thumbNailMode, setThumbNailMode] = React.useState(props.thumbNailMode);

    const [dataSource, setDataSource] = React.useState<Record<string, any>[]>();
    const [columnDefs, setColumnDefs] = React.useState<FormFieldRecord[]>();
    const [gridWidth, setGridWidth] = React.useState<number>(0);
    const [maxScrollableSize, setMaxScrollableSize] = React.useState<{ width: number; height: number }>({ width: window.innerWidth, height: window.innerHeight });

    const style = props.styles ? { ...props.styles } : {};
    style.fontFamily = style.fontFamily ? style.fontFamily : app.themes.defaultFonts;
    if (!style.fontSize) {
        style.fontSize = (app.themes.fontSize ? app.themes.fontSize : 14) + "px";
    }
    style.color = style.color ? style.color : app.themes.foreColor;

    const gridStyles = props.gridStyles ? { ...props.gridStyles } : {} as GridStylesRecord;
    gridStyles.captionFontSize = gridStyles.captionFontSize ? gridStyles.captionFontSize : 12;
    gridStyles.rowHeight = gridStyles.rowHeight ? gridStyles.rowHeight : parseInt(style.fontSize.slice(0, -2));
    gridStyles.maxColWidth = gridStyles.maxColWidth ? gridStyles.maxColWidth : 500;
    gridStyles.showCaptions = ("showCaptions" in props) ? props.showCaptions : true;
    if (gridStyles.showCaptions) {
        gridStyles.captionHeight = gridStyles.captionHeight ? gridStyles.captionHeight : gridStyles.captionFontSize + 12;
    } else {
        gridStyles.captionHeight = 0;
    }
    gridStyles.horizontalCellPadding = props.horizontalCellPadding ? props.horizontalCellPadding : 3;
    const fixedBackColor = "lightgray";
    const fixedRows = gridStyles.showCaptions ? 1 : 0;
    const fixedCols = props.fixedCols ? props.fixedCols : 0;
    const altRowBackColor = app.themes.backColor10 ? app.themes.backColor10 : "lightblue";

    React.useEffect(() => {
        setDataSource(props.dataSource);
        calcAndSetColWidths(props.dataSource, props.columnDefs);
    }, []);
    React.useEffect(() => {
        if (props.isControlled) {
            // parent is maintaining data so make sure it is synced with local copy
            if (!deepEqual(props.dataSource, dataSource)) {
                setDataSource(deepcopy(props.dataSource));
                calcAndSetColWidths(props.dataSource, props.columnDefs);
            }
        }
    });
    
    React.useLayoutEffect(() => {
        if (scrollableRef.current) {
            //     const gridRect = gridRef.current.getBoundingClientRect();
            const scrollableRect = scrollableRef.current.getBoundingClientRect();
            //      console.log("scrollableRect:", scrollableRect)
            const maxScrollable = { width: window.innerWidth - scrollableRect.x - 18, height: window.innerHeight - scrollableRect.y - 18 };
            if (maxScrollableSize.width !== maxScrollable.width || maxScrollableSize.height !== maxScrollable.height) {
                //           console.log("setting maxScrollableSize to ", maxScrollable)
                setMaxScrollableSize(maxScrollable);
            }
        }

        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, [dataSource]);

    React.useEffect(() => {
        if (scrollHome && fixedColsRef.current) {
            fixedColsRef.current.scrollTop = 0;
            fixedRowsRef.current.scrollTop = 0;
            scrollableRef.current.scrollTop = 0;
        }
        setScrollHome(false);
    }, [scrollHome]);

    document.addEventListener("keyup", e => {
        //      console.log(e);
        if (e.code === "Home" && e.ctrlKey) {
            setScrollHome(true);
        } else if (e.code === "Escape") {
            setContextMenuInfo(null);
            setEditorCell(null);
        }
    });
    const textEditorKeyUp = (e: React.KeyboardEvent) => {
        if (e.nativeEvent.code === "Escape") {
            setEditorCell(null);
        }
    }

    //-------- COLUMN WIDTH HANDLING ---------------
    const calcAndSetColWidths = (dataSource: Record<string, any>[], colDefs: FormFieldRecord[]) => {
        const [newColumns, newGridWidth] = calcColWidths(dataSource, colDefs);
        if (!deepEqual(colDefs, newColumns)) {
            setColumnDefs(newColumns);
        }
        if (newGridWidth !== gridWidth) {
            setGridWidth(newGridWidth);
        }
    }

    // column widths are set per following guidelines:
    //  lesser of grid column width or colDef.width but no less than caption column width
    // data must be valid before calling
    // returns updated copy of columnDefs and updated width of full grid (both visible and overflow)
    const calcColWidths = (dataSource: Record<string, any>[], columnDefs: FormFieldRecord[]): [FormFieldRecord[], number] => {
        const logCol = -1;      // -1 to turn off
        const fudgeFactor = 1.03;       // add 3% to fudge for measureText inaccuracy
        const newColumns: FormFieldRecord[] = deepcopy(columnDefs);
        let newGridWidth = 0;
        newColumns.forEach((def, col) => {
            if (!("visible" in def)) {
                def.visible = true;
            }
            if (!def.type) {
                def.type = FormFieldType.text;
            }
            let width;
            if (def.fixedWidth) {
                width = def.fixedWidth;
            } else {
                width = measureText(" " + def.label + " ", gridStyles.captionFontSize + "px " + style.fontFamily) * fudgeFactor + gridStyles.horizontalCellPadding!;
                if (col === logCol) {
                    console.log("caption cell: " + def.name + "; value=" + def.label + ", width=" + width);
                }
                if (gridStyles.minColWidth) {
                    width = Math.max(width, gridStyles.minColWidth);
                }
                const maxWidth = gridStyles.maxColWidth;
                for (let row = 0; row < dataSource.length; row++) {
                    if (isRowVisible(dataSource[row].rowState) && def.visible) {
                        let value = dataSource[row][def.name];
                        if (props.customFormatter) {
                            const formatted = props.customFormatter(dataSource[row], def.name, value);
                            value = formatted ? formatted : value;
                        }
                        let textWidth = measureText(value, style.fontSize + " " + style.fontFamily) * fudgeFactor + gridStyles.horizontalCellPadding!;

                        if (props.allowEditing && def.allowEditing && (def.type === FormFieldType.int || def.type === FormFieldType.money)) {
                            textWidth += 8;     // add for up/down chevrons
                        }
                        // if (col === logCol) {
                        //     console.log(def.name + ": value=" + value + "; textWidth=" + textWidth + ", gridStyles.maxColWidth=" + gridStyles.maxColWidth);
                        // }
                        width = Math.max(width, textWidth);
                        //   console.log(value + "=>" + measureText(value, fontSize + "px " + fontFamily))
                        if (width > maxWidth!) {
                            width = maxWidth!;       // once a cell exceeds max width we can set to max and skip the rest
                            break;

                        }
                    }
                }
            }
            if (sortedColumn && sortedColumn.col === col) {
                width += 16;
            }
            if (col === logCol) {
                console.log("col " + def.name + " final width=" + width);
            }
            def.width = width;
            if (def.visible) {
                newGridWidth += width + 8;
            }
        });
        return [newColumns, newGridWidth];
    }
    //-------- END COLUMN WIDTH HANDLING ---------------

    const addRowState = (dataRow: Record<string, any>, rowState: RowState) => {
        if (!dataRow.rowState) {
            dataRow.rowState = rowState;
        } else {
            dataRow.rowState |= rowState;
        }
    }
    const isRowVisible = (rowState: RowState): boolean => {
        return !rowState || (rowState & RowState.deleted) === 0;
    }

    const getValue = (row: number, col: number): any => {
        if (!dataSource || !columnDefs) {
            return null;
        }
        return dataSource[row][columnDefs[col].name];
    }
    const setValue = (row: number, col: number, value: any) => {
        if (dataSource && columnDefs) {
            if (value !== getValue(row, col)) {
                const newData = deepcopy(dataSource);
                newData[row][columnDefs[col].name] = value;
                addRowState(newData[row], RowState.modified);
                updateDataAndColumns(newData);
                if (props.onChange) {
                    props.onChange(newData, props.id);
                }
            }
        }
    }
    // call this only when data has changed; call with copy of dataSource
    const updateDataAndColumns = (newDataSource: Record<string, any>[]) => {
        //        console.log("setting data to"); console.log(dataSource);
        setDataSource(newDataSource);
        calcAndSetColWidths(newDataSource, columnDefs!);
    }

    const formatValue = (colDef: FormFieldRecord, customFormatter: CustomFormatterCallback | undefined, value: any, row: number): string => {
        //   console.log("formatValue(" + value + ", " + row + ", " + col);
        if (customFormatter) {
            const val = customFormatter(dataSource![row], colDef.name, value);
            if (val !== null) {
                return val;
            }
        }
        if (colDef.type === FormFieldType.money && !(value + '').startsWith('$')) {
            // money type but not passed as string so format it here
            value = parseFloat(value).toFixed(2);
        }
        // money or text shows as string
        return value;
    }

    // return "flex-start", "flex-end" or "center" for cell content
    const setJustify = (def: FormFieldRecord): string => {
        if (def.align === FormFieldAlignType.left) {
            return "flex-start";
        } else if (def.align === FormFieldAlignType.center) {
            return "center";
        } else if (def.align === FormFieldAlignType.right) {
            return "flex-end";
        } else if (def.type === FormFieldType.money || def.type === FormFieldType.int) {
            return "flex-end";
        } else if (def.type === FormFieldType.text) {
            return "flex-start";
        }
        return "center";
    }
    //------------- CONTEXT MENU ----------------------
    // pass rowIndex -1 to add to end
    const handleInsertRow = (rowIndex: number) => {
        const newRow = {} as Record<string, any>;
        columnDefs!.forEach(def => {
            newRow[def.name] = null;
        });
        if (props.newRow) {
            props.newRow(newRow);
        }
        newRow.rowState = RowState.added;
        const newDataSource = deepcopy(dataSource);
        if (rowIndex === -1) {
            rowIndex = newDataSource.length;
            newDataSource.push(newRow);
        } else {
            newDataSource.splice(rowIndex, 0, newRow);
        }
        setDataSource(newDataSource);
        if (props.onChange) {
            props.onChange(newDataSource, props.id);
        }
        setContextMenuInfo(null);
        setFocusedRow(rowIndex);
    }
    const handleDeleteRow = (rowIndex: number) => {
        const newDataSource = deepcopy(dataSource);
        newDataSource[rowIndex].rowState = RowState.deleted;
        setDataSource(newDataSource);
        if (props.onChange) {
            props.onChange(newDataSource, props.id);
        }
        setContextMenuInfo(null);
    }
    const contextMenuInvoked = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!props.contextMenuInvoked && !props.allowDelete && !props.newRow) {
            return;
        }
        const [row, col] = extractRowCol(e.target as HTMLElement);
        let contextMenu = [];
        if (props.allowInsert) {
            contextMenu.push(new ContextMenuItem("Insert new row here", handleInsertRow, row));
            contextMenu.push(new ContextMenuItem("Add row to end", handleInsertRow, -1));
        }
        if (props.allowDelete) {
            contextMenu.push(new ContextMenuItem("Delete this row", handleDeleteRow, row));
        }
        if (props.contextMenuInvoked) {
            contextMenu = contextMenu.concat(props.contextMenuInvoked(new Cell(row, col, dataSource![row], columnDefs![col].name, e.target as HTMLElement)));
        }
        if (contextMenu.length) {
            e.preventDefault();
            setContextMenuInfo(new ContextMenuInfo({ x: e.clientX, y: e.clientY }, contextMenu));
        }
    }
    //-------------- CELL EDITING ---------------------
    // click is sent back to caller if callback provided
    const cellClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        const [row, col] = extractRowCol(e.target as HTMLElement);
        if (row === -1) return;
        /*
        if (row === -1 && props.allowSorting) {
            // user wants to sort on clicked column
            let sortCol = { col, sortAscending: sortedColumn && sortedColumn.col === col ? !sortedColumn.sortAscending : true };
            sortData(sortCol);
            setSortedColumn(sortCol);
            console.log("sorted"); console.log(data);
            return;
        }
        */
        const cell = new Cell(row, col, dataSource![row], columnDefs![col].name, e.target as HTMLDivElement);
        if (props.cellClicked) {
            if (!props.cellClicked(cell)) {
                return;
            }
        }
        const def = columnDefs![col];
        if (props.allowEditing && def.allowEditing) {
            console.log("setting editorCell to", cell)
            setEditorCell(cell);
        }
    }
    const NumericEditor: React.FC = () => {
        if (!editorCell) {
            return null;
        }
        const rect = editorCell.element.getBoundingClientRect();
        const scrollableRect = scrollableRef.current.getBoundingClientRect();

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            setValue(editorCell.rowIndex, editorCell.colIndex, e.target.value);
            setEditorCell(null);
        }
        return (
            <StyledNumericEditor ref={editorRef as React.MutableRefObject<HTMLInputElement>}
                top={rect.top - scrollableRect.top + scrollableRef.current.scrollTop}
                left={rect.left - scrollableRect.left + scrollableRef.current.scrollLeft}
                width={rect.width - 2} height={rect.height - 2}>
                <RifmNumeric name="samgridnumeric" fieldType={FormFieldType.int} initialValue={getValue(editorCell.rowIndex, editorCell.colIndex)} onBlur={handleBlur} />
            </StyledNumericEditor>
        );
    }
    const TextEditor: React.FC = () => {
        if (!editorCell) {
            return null;
        }
        const rect = editorCell.element.getBoundingClientRect();
        const scrollableRect = scrollableRef.current.getBoundingClientRect();
        const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
            setValue(editorCell.rowIndex, editorCell.colIndex, e.target.value);
            setEditorCell(null);
        }
        return (
            <StyledTextEditor ref={editorRef as React.MutableRefObject<HTMLTextAreaElement>}
                top={rect.top - scrollableRect.top + scrollableRef.current.scrollTop}
                left={rect.left - scrollableRect.left + scrollableRef.current.scrollLeft}
                width={(columnDefs![editorCell!.colIndex].fixedWidth ? columnDefs![editorCell!.colIndex].fixedWidth! : 250) - 2} height={gridStyles.rowHeight! * 3 + 3}
                defaultValue={getValue(editorCell.rowIndex, editorCell.colIndex)} onBlur={handleBlur} onKeyUp={textEditorKeyUp} />
        );
    }
    let editorCellType = null;
    if (editorCell) {
        const def = columnDefs![editorCell.colIndex];
        if (def.type === FormFieldType.int || def.type === FormFieldType.money) {
            editorCellType = "number";
        } else if (def.type === FormFieldType.image && thumbNailMode) {
            editorCellType = "image";
        } else if (def.type != FormFieldType.image) {
            editorCellType = "text";
        }
    }
    // return [row, col] as found in element.id
    const extractRowCol = (element: HTMLElement): [number, number] => {
        const id = getNearestParentId(element).id;
        return parseRowCol(id);
    }
    const parseRowCol = (id: string): [number, number] => {
        const parts = id.split(",");
        return [parseInt(parts[0]), parseInt(parts[1])];
    }
    //-------------- END CELL EDITING ---------------------
    // props: isFixedRow, rowIndex, rowCount, colIndex, colCount, customFormatter, backColor
    interface RowGroupProps {
        rowIndex: number;
        rowCount: number;
        colIndex: number;
        colCount: number;
        isFixedRow?: boolean;
        backColor?: string;
        customFormatter?: CustomFormatterCallback;
    }
    const RowGroup: React.FC<RowGroupProps> = (props) => {
        if (!dataSource) {
            return null;
        }
    //    console.log("RowGroup: from=" + props.rowIndex + ", count=" + props.rowCount + ", isCaption=" + props.isFixedRow)
        return (
            <div>
                {dataSource.map((dataRow, index) => {
                    return (index >= props.rowIndex && index < props.rowIndex + props.rowCount && isRowVisible(dataRow.rowState) &&
                        <Row key={index} isFixedRow={props.isFixedRow} colIndex={props.colIndex} colCount={props.colCount} rowIndex={index} backColor={props.backColor}
                            customFormatter={props.customFormatter} />
                    );
                })}
            </div>
        )
    }

    const StyledImageCell = styled.img`
        max-width: 92%;
        max-height:  92%;
    `
    interface RowProps {
        colIndex: number;
        rowIndex: number;
        isFixedRow?: boolean;
        colCount: number;
        backColor?: string;
        customFormatter?: CustomFormatterCallback;
    }
    const Row: React.FC<RowProps> = (props) => {
        //         console.log("Row: from=" + props.colIndex + ", count=" + props.colCount + ", isFixedRow=" + props.isFixedRow)
        return (
            <StyledRow>
                {columnDefs!.map((def, index) => {
                    let value = formatValue(def, props.customFormatter, getValue(props.rowIndex, index), props.rowIndex);
                    if (typeof value === "boolean") {
                        value = value ? "1" : "0";
                    }
                    if (def.type === FormFieldType.staticImage) {
                        value = def.staticImageUrl as string;
                    }
                    //            console.log("row/col " + props.rowIndex + "," + index + ": def.type=" + def.type + ", value=" + value)
                    return (index >= props.colIndex && index < props.colIndex + props.colCount && def.visible && (
                        props.isFixedRow ? (
                            <StyledGridCell key={def.name} id={"-1," + index} height={gridStyles.captionHeight!} backColor={fixedBackColor}
                                width={def.width!} justifyContent={"center"} horizontalPadding={gridStyles.horizontalCellPadding!} onClick={cellClicked}>
                                {def.label}
                            </StyledGridCell>
                        ) : (
                            <StyledGridCell key={def.name} id={props.rowIndex + "," + index} height={gridStyles.rowHeight!} justifyContent={setJustify(def)}
                                width={def.width!} horizontalPadding={gridStyles.horizontalCellPadding!} onClick={cellClicked} onContextMenu={contextMenuInvoked}
                                backColor={props.backColor} cursor={def.cursor}>
                                {def.type === FormFieldType.image || def.type === FormFieldType.staticImage ? (
                                    <StyledImageCell src={value} />
                                ) : (
                                    def.type === FormFieldType.checkbox ? (
                                        <input type="checkbox" defaultChecked={parseInt(value) === 1} key={def.name} id={props.rowIndex + "," + index} onClick={cellClicked} onContextMenu={contextMenuInvoked} />
                                    ) : (
                                    def.type === FormFieldType.icon ? (
                                        <i style={{fontSize: def.iconFontSize ? (def.iconFontSize + "px") : style.fontSize}} className={def.icon}></i> 
                                    ) : (
                                    <span>{value}</span>
                                )))}
                            </StyledGridCell>
                        )

                    ))
                })}
            </StyledRow>
        )
    }

    if (!dataSource || !columnDefs) {
        return null;
    }

    const fixedWidth = maxScrollableSize.width;
    const fixedHeight = maxScrollableSize.height;
    const marginLeft = props.marginLeft ? props.marginLeft : gridWidth >= window.innerWidth ? 0 : (window.innerWidth - gridWidth) / 2;
    const fixedColsStyle = { overflow: "auto", scrollbarWidth: "none", flexShrink: 0 } as Record<string, any>;
    const scrollableColsStyle = { position: "relative", width: fixedWidth + "px", overflow: "auto", scrollbarWidth: "auto" } as Record<string, any>;
    if (!props.suppressScrolling) {
        fixedColsStyle.height = fixedHeight + "px";
        scrollableColsStyle.height = fixedHeight + "px";
    }
    return (
     <ScrollSync>
            <GridContainer style={style} marginLeft={marginLeft}>
                <CaptionContainer>
                    <RowGroup isFixedRow={true} rowIndex={0} rowCount={fixedRows} colIndex={0} colCount={fixedCols} customFormatter={props.customFormatter} />
                    <ScrollSyncPane group="horizontal">
                        <div ref={fixedRowsRef} className="noScrollbar" style={{ width: fixedWidth + "px", overflow: "auto", scrollbarWidth: "none", flexShrink: 0 }}>
                            <RowGroup isFixedRow={true} rowIndex={0} rowCount={fixedRows}
                                colIndex={fixedCols} colCount={columnDefs.length - fixedCols} customFormatter={props.customFormatter} />
                        </div>
                    </ScrollSyncPane>
                </CaptionContainer>
                <NonCaptionContainer>
                    <ScrollSyncPane group="vertical">
                        <div ref={fixedColsRef} className="noScrollbar" style={fixedColsStyle}>
                            <RowGroup rowIndex={0} rowCount={dataSource.length} backColor={fixedBackColor}
                                colIndex={0} colCount={fixedCols} customFormatter={props.customFormatter} />
                        </div>
                    </ScrollSyncPane>
                    <ScrollSyncPane group={["vertical", "horizontal"] as unknown as string}>
                        <div ref={scrollableRef} style={scrollableColsStyle}>
                            <RowGroup rowIndex={0} rowCount={dataSource.length} colIndex={fixedCols}
                                colCount={columnDefs.length - fixedCols} customFormatter={props.customFormatter} />
                            {editorCellType === "text" && <TextEditor />}
                            {editorCellType === "number" && <NumericEditor />}
                        </div>
                    </ScrollSyncPane>
                </NonCaptionContainer>
                {contextMenuInfo && <ContextMenu info={contextMenuInfo} closePopup={() => setContextMenuInfo(null)} />}
            </GridContainer>
      </ScrollSync>
    );
}

export const isDirty = (dataSource: Record<string, any>[]): boolean => {
    for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].rowState) {
            return true;
        }
    }
    return false;
}
export const isRowDeleted = (dataRow: Record<string, any>): boolean => {
    return dataRow.rowState && (dataRow.rowState & RowState.deleted) !== 0
}
// returned cleaned up data (deleted rows removed and all rowState values removed)
export const acceptChanges = (dataSource: Record<string, any>[]) => {
    const newData = [] as Record<string, any>[];
    dataSource.forEach(dataRow => {
        if (!isRowDeleted(dataRow)) {
            delete dataRow.rowState;
            newData.push(dataRow);
        }
    });
    return newData;
}

export default SamGrid;



