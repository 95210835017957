import React from 'react';
import styled from 'styled-components';

import { reorgArray } from './dashboard-support';
import { ButtonsRow, copyArrayWithUpdatedElement, formatImageOrVideoSrcWithFile, getNearestParentId, isImageFile } from '../libSupport';
import { useTokens } from '../SamState';
import IconButton from '../IconButton';
import useFormMgr from '../forms/useFormMgr';
import SamForm from '../forms/SamFormV4';
import { usePostApi } from '../useDataApiV2';
import SamModal from '../SamModal';
import SamTextEditor, { stripPreAndPostPTags } from './SamTextEditor';

import { FormFieldRecord, FormFieldType, GraphicDimensionType, GraphicFloatType, ImageDragType, ImageFileOptions, ImageRecord, RowState } from '../../interfaces/lib-api-interfaces';
import { VerifyUrlResult } from '../../interfaces/lib-api-interfaces';
import { IconButtonProps } from '../../interfaces/lib-react-interfaces';

const MasterContainer = styled.div<{ direction: string; fontSize: number }>`
    display: flex;
    justify-content: center;
    flex-direction: ${props => props.direction};
    font-size: ${props => props.fontSize}px;
    flex-wrap: wrap;
`
export enum CaptionOptionsEnum { allow = 'A', disallow = 'D', readOnly = 'R' }
export enum CaptionFormatEnum { html = 'H', plain = 'P' }
export interface ImageAttributesEditOptions {
    verifyUrlApiUrl?: string;       // if omitted links are not allowed
    captions: CaptionOptionsEnum;
    captionFormat?: CaptionFormatEnum;
    allowResize?: boolean;
    allowFloat?: boolean;       // aka "wrap"
}
export interface ImageUploadOptions {
    // following must be passed to enable choosing new image from file system; if not passed new images will not be allowed
    targetDomain: string;
    uploadImageApiUrl: string;
}
export enum AttributesPosition { right, bottom, hide }
/*
export interface ImageFileOptions {
    graphicsSubfolder?: string;      // folder under "graphics": "other", "blog", etc.
    sizeDesignator?: ImageSizeEnum;     // full or magnified if image is stored in 2 versions
    size?: number;
    dimension?: GraphicDimensionType;
}
*/
/* example render with all possible props:
<ImageListHandler id="products" images={imageList} imageBoxWidth={300} direction="row" allowAddNewVideo={true} attributesPosition={AttributesPosition.right} size={200}
    customButtons={{ [{ id: "submit", caption: "Submit"] }} customButtonClicked={buttonClicked} style={{ marginLeft: "8px" }} fontSize={13} onChange={imagesChanged}
    editOptions={{ verifyUrlApiUrl: api.verifyUrl, captions: CaptionOptionsEnum.allow, captionFormat: CaptionFormatEnum.html, allowResize: true, allowFloat: false }}
    uploadOptions={{ targetDomain: app.targetDomain, uploadImageApiUrl: api.uploadImage }}
    fileOptions={{ graphicsSubfolder: "other", sizeDesignator: ImageSizeEnum.full, size: 500, dimension: GraphicDimensionType.width }}
/>
*/

const ImageBox = styled.div<{ attributesPosition: AttributesPosition; width: number }>`
    display: flex;
    flex-direction: ${props => props.attributesPosition === AttributesPosition.bottom ? "column" : "row"};
    width: ${props => props.width}px;
    padding: 8px;
    margin: 8px;
    border: 1px solid;
`
// direction determines image/attribute placement:
//      "row" puts image above attributes (i.e., left to right)
//      "column" puts image to left of attributes (i.e., top to bottom)
interface ImageListHandlerProps {
    id: string;         // must be unique if more than one set of images on page
    images: ImageRecord[];      // filenames should not have _f or _m inserted; it is inserted based on fileOptions.sizeDesignator
    imageBoxWidth?: number;      // defaults to 300px; if direction is column this is width of component; if direction is row this is width of each element
    maxImages?: number;         // defaults to unlimited
    editOptions: ImageAttributesEditOptions;
    uploadOptions?: ImageUploadOptions;
    fileOptions: ImageFileOptions;
    direction: string;  // row or column
    allowAddNewVideo?: boolean;
    attributesPosition: AttributesPosition;
    // following is height of each ROW or width of each COLUMN
    size?: number;       // size in dashboard editor; defaults to 100px; if direction is row image width is fixed to this; if direction is column image height is fixed to this

    customButtons?: IconButtonProps[];
    customButtonClicked?: (id: string, image: ImageRecord) => void;     // required if customButtons passed

    style?: Record<string, any>;
    fontSize?: number;  // defaults to 13px
    onChange: (images: ImageRecord[], id: string) => void;
}
const ImageListHandler: React.FC<ImageListHandlerProps> = (props) => {
    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const imageBoxWidth = props.imageBoxWidth ? props.imageBoxWidth : 300;
    const imageSize = props.size ? props.size : 100;
    const fontSize = props.fontSize ? props.fontSize : 13;

    //   console.log("ImageListHandler:", props)

    // move target to source and renumber
    const reorgImages = (source: number, target: number) => {
        props.onChange(reorgArray<ImageRecord>(props.images, source, target, true, true), props.id);
    }
    // insert new image from file system; target = -1 to add to end
    const insertImage = (newImage: ImageRecord, target: number) => {
        const newImages: ImageRecord[] = [];
        if (props.fileOptions.dimension) {
            newImage.dimension = props.fileOptions.dimension;
        }
        if (props.fileOptions.size) {
            newImage.size = props.fileOptions.size;
        }
        props.images.forEach((image, index) => {
            if (index === target) {
                newImages.push(newImage);
            }
            newImages.push({ ...image });
        });
        if (target === -1 || target === props.images.length) {
            newImages.push(newImage);
        }
        props.onChange(newImages, props.id);
    }
    // data is index of image being dropped onto "new image" area
    const imageDroppedAtEnd = (data: string) => {
        reorgImages(parseInt(data), props.images.length);
    }

    const imageChanged = (newImage: ImageRecord, imageIndex: number) => {
        props.onChange(copyArrayWithUpdatedElement(props.images, newImage, imageIndex), props.id);
    }
    const imageDeleted = (imageIndex: number) => {
        const newImages: ImageRecord[] = [];
        props.images.forEach((image, index) => {
            const newImage = { ...image };
            if (index === imageIndex) {
                newImage.rowState = RowState.deleted;
            }
            newImages.push(newImage);
        });
        props.onChange(newImages, props.id);
    }

    const handleAddVideo = (image: ImageRecord | null) => {
        if (image) {
            image.rowState = RowState.added;
            if (props.fileOptions.size) {
                image.size = props.fileOptions.size;
            }
            const newImages = props.images.map(image => image);
            newImages.push(image);
            props.onChange(newImages, props.id);
        }
    }
    const newImageSelected = (image: ImageRecord | null) => {
        if (image) {
            if (image.file) {
                handleAddVideo(image);
            } else {
                insertImage(image, -1);
            }
        }
    }

    return (
        <MasterContainer direction={props.direction} fontSize={fontSize}>
            {props.images.map((image, index) => {
                return (
                    (image.rowState! & RowState.deleted) === 0 &&
                    (
                        props.attributesPosition === AttributesPosition.hide ? (
                            <div key={image.filename ? image.filename : image.youtube_id} style={{ padding: "8px" }}>
                                <ILHImage id={props.id} index={index} image={image} size={imageSize} attributesPosition={props.attributesPosition} fileOptions={props.fileOptions}
                                    sizeConstrained={false}
                                    reorgImages={reorgImages} insertImage={insertImage} />
                            </div>
                        ) : (
                            <ImageBox key={image.filename ? image.filename : image.youtube_id} width={imageBoxWidth} attributesPosition={props.attributesPosition}>
                                <ILHImage id={props.id} index={index} image={image} size={imageSize} attributesPosition={props.attributesPosition}
                                    fileOptions={props.fileOptions}
                                    sizeConstrained={true}
                                    reorgImages={reorgImages} insertImage={insertImage} />
                                <ImageAttributesBox image={image} index={index} editOptions={props.editOptions}
                                    fileOptions={props.fileOptions}
                                    attributesPosition={props.attributesPosition} onChange={imageChanged} imageDeleted={imageDeleted}
                                    customButtons={props.customButtons} customButtonClicked={props.customButtonClicked} />
                            </ImageBox>
                        )
                    ))
            })}
            {(props.uploadOptions || props.allowAddNewVideo) &&
                <ImageInput
                    flags={(props.uploadOptions ? ImageInputFlags.allowImage : 0)
                        | (props.allowAddNewVideo ? ImageInputFlags.allowVideo : 0)
                        | (ImageInputFlags.allowDragFromFileSystem)}
                    fileOptions={props.fileOptions}
                    uploadOptions={props.uploadOptions} size={imageSize} dimension={GraphicDimensionType.height} onImageSelected={newImageSelected} onImageDropped={imageDroppedAtEnd} />
            }
        </MasterContainer>
    )

}
//-------------------------------------------------------------
const ImageAttributes = styled.div<{ marginLeft: number; marginTop: number; }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: ${props => props.marginLeft}px;    // for image on left
    margin-top: ${props => props.marginTop}px;      // for image on top
`
const TagText = styled.p`
    font-weight: bold;
    margin: 0;
`
const TextRow = styled.div`
    text-align: left;
    margin-bottom: 4px;
`
const LabelText = styled.span`
    font-style: italic;
    color: #666;
`
interface ImageAttributesBoxProps {
    image: ImageRecord;
    index: number;
    attributesPosition: AttributesPosition;
    editOptions: ImageAttributesEditOptions;
    fileOptions: ImageFileOptions;
    onChange: (image: ImageRecord, index: number) => void;
    onCancel?: () => void;       // attributes were edited and user hit cancel
    imageDeleted: (index: number) => void;
    customButtons?: IconButtonProps[];
    customButtonClicked?: (id: string, image: ImageRecord) => void;     // required if customButtons passed
}
const ImageAttributesBox: React.FC<ImageAttributesBoxProps> = (props) => {
    // const [editingCaption, setEditingCaption] = React.useState<string>();
    // const [editingLink, setEditingLink] = React.useState<string>();
    // const [editingSize, setEditingSize] = React.useState(0);
    const [editingAttributes, setEditingAttributes] = React.useState(false);
    const [confirmingDelete, setConfirmingDelete] = React.useState<string>();
    const [verifyLink, setVerifyLink] = React.useState<string>();   // the new url; if it verifies save in image and call props.onChange
    const [image, setImage] = React.useState<ImageRecord>();        // needed to keep changed attributes while link is being verified and redisplay updated attributes properly

    const { post } = usePostApi();
    const { getToken } = useTokens();

    React.useEffect(() => {
        setImage(props.image);
    }, []);
    React.useEffect(() => {
        //      console.log("useEffect: verifyLink=" + verifyLink)
        if (verifyLink) {
            post(props.editOptions.verifyUrlApiUrl!, { url: verifyLink }, linkVerified, () => alert("Server error trying to verify link"), getToken()!.token);
            setVerifyLink(undefined);
        }
    }, [verifyLink]);

    // api returns the link without https:// etc., or null if link is invalid
    const linkVerified = (url: string, status: number | undefined) => {
        const result = status as VerifyUrlResult;
        if (result === VerifyUrlResult.valid) {
            //        console.log("link verified: " + url)
            const newImage = { ...image, url };
            newImage.rowState! |= RowState.modified;
            setEditingAttributes(false);
            setImage(newImage);
            props.onChange(newImage, props.index);
        } else {
            alert("Link url is invalid. Please fix.")
        }
    }
    const editAttributesSubmitted = (editedImage: ImageRecord | null) => {
        if (editedImage) {
            setImage({ ...image, caption: editedImage.caption, size: editedImage.size, float: editedImage.float });
            if (props.editOptions.verifyUrlApiUrl && editedImage.url !== image!.url) {
                if (editedImage.url!.startsWith("/")) {
                    editedImage.url = editedImage.url!.slice(1);
                }
                if (editedImage.url!.endsWith("/")) {
                    editedImage.url = editedImage.url!.slice(0, -1);
                }
                setVerifyLink(editedImage.url);       // don't upload to web and call onChange until link is verified
            } else {
                setEditingAttributes(false);        // close modal
                editedImage.rowState! |= RowState.modified;
                props.onChange(editedImage, props.index);
            }
        } else {
            setEditingAttributes(false);        // close modal
            if (props.onCancel) {
                props.onCancel();
            }
        }
    }
    const confirmDeleteSubmitted = (confirmed: boolean) => {
        setConfirmingDelete(undefined);
        if (confirmed) {
            props.imageDeleted(props.index);
        }
    }

    let marginLeft: number;
    let marginTop: number;
    if (props.attributesPosition === AttributesPosition.right) {
        marginLeft = 8;
        marginTop = 0;
    } else {
        marginLeft = 0;
        marginTop = 8;
    }

    if (!image) {
        return null;
    }

    let float = '';
    if (props.editOptions.allowFloat) {
        if (image.float === GraphicFloatType.left) {
            float = "Left";
        } else if (image.float === GraphicFloatType.right) {
            float = "Right";
        } else {
            float = "None";
        }
    }
    const customButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        props.customButtonClicked!((e.target as HTMLButtonElement).id, image);
    }
    return (
        <ImageAttributes marginLeft={marginLeft} marginTop={marginTop}>
            {image.tag && <TagText>{image.tag}</TagText>}
            <ButtonsRow>
                {(props.editOptions.captions === CaptionOptionsEnum.allow || props.editOptions.verifyUrlApiUrl || props.editOptions.allowResize || props.editOptions.allowFloat) &&
                    <IconButton caption="Edit attributes" icon="fas fa-edit" onClick={() => setEditingAttributes(true)} />
                }
                <IconButton style={{ marginLeft: "8px" }} caption="Delete" icon="fa fa-trash" onClick={() => setConfirmingDelete(image.filename)} />
            </ButtonsRow>
            {props.customButtons &&
                props.customButtons.map(button => {
                    return (
                        <ButtonsRow key={button.id} height={32}>
                            <IconButton {...button} onClick={customButtonClicked} />
                        </ButtonsRow>
                    )
                })
            }
            {(props.editOptions.captions === CaptionOptionsEnum.allow || props.editOptions.captions === CaptionOptionsEnum.readOnly) &&
                <TextRow>
                    <LabelText>Caption:&nbsp;</LabelText>
                    <span dangerouslySetInnerHTML={{ __html: image.caption ? image.caption : "(none)" }} />
                </TextRow>
            }
            {props.editOptions.verifyUrlApiUrl &&
                <TextRow>
                    <LabelText>Link:&nbsp;</LabelText>
                    <span>{image.url ? image.url : "(none)"}&nbsp;</span>
                </TextRow>
            }
            {image.size &&
                <TextRow>
                    <LabelText>Size:&nbsp;</LabelText>
                    <span>{image.size + "px " + (image.dimension === GraphicDimensionType.height ? "high" : "wide")}</span>
                </TextRow>
            }
            {float &&
                <TextRow>
                    <LabelText>Wrap:&nbsp;</LabelText>
                    <span>{float}</span>
                </TextRow>
            }
            <TextRow>
                {image.filename ? (
                    <React.Fragment>
                        <LabelText>File:&nbsp;</LabelText>
                        <span>{image.filename}&nbsp;</span>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <LabelText>YouTube ID:&nbsp;</LabelText>
                        <span>{image.youtube_id}</span>
                    </React.Fragment>
                )}
            </TextRow>
            {editingAttributes && <EditAttributesDlg image={image} editOptions={props.editOptions} fileOptions={props.fileOptions}
                onSubmit={editAttributesSubmitted} />
            }
            {confirmingDelete && <ConfirmDeleteDlg filename={confirmingDelete} onSubmit={confirmDeleteSubmitted} />}
        </ImageAttributes>
    )
}
//-------------------------------------------------------------
// Combined attributes editor: caption, link and size
const EditAttributesContainer = styled.div`
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        font-size: 18px;
        margin: 0;
    }
`
const TitleText = styled.p`
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 0;
`
const StyledImageBox = styled.div`
    width: 300px;
  /*  height: 300px;    we may need to remove width depending on dimension */
    display: flex;
    justify-content: center;
    align-items: center;
`
const StyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
`
interface EditAttributesDlgProps {
    image: ImageRecord;
    editOptions: ImageAttributesEditOptions;
    fileOptions: ImageFileOptions;
    onSubmit: (image: ImageRecord | null) => void;
    customButtons?: IconButtonProps[];
    customButtonClicked?: (id: string) => void;     // required if customButtons passed
}
const EditAttributesDlg: React.FC<EditAttributesDlgProps> = (props) => {
    return (
        <SamModal component={EditAttributesModal as React.FC} componentProps={props} />
    )
}
const EditAttributesModal: React.FC<EditAttributesDlgProps> = (props) => {
    const [image, setImage] = React.useState<ImageRecord>();
    const [captionEditor, setCaptionEditor] = React.useState<any>();

    const forms = useFormMgr();

    React.useEffect(() => {
        setImage(props.image);
    }, []);

    const formChanged = (values: Record<string, any>) => {
        const newImage = { ...image };
        if (props.editOptions.allowResize) {
            newImage.size = values.size;
        }
        if (props.editOptions.verifyUrlApiUrl) {
            newImage.url = values.link;
        }
        if (props.editOptions.allowFloat) {
            newImage.float = values.float;
        }
        if (props.editOptions.captions === CaptionOptionsEnum.allow && props.editOptions.captionFormat === CaptionFormatEnum.plain) {
            newImage.caption = values.caption;
        }
        setImage(newImage);
    }
    const onSubmit = () => {
        const newImage = { ...image };
        if (captionEditor) {
            newImage.caption = stripPreAndPostPTags(captionEditor.getContent());
        }
        props.onSubmit(newImage);
    }

    if (!image) {
        return null;
    }
    //  console.log("image:", image)

    const fields: FormFieldRecord[] = [];
    const initialValues: { size?: number; link?: string; caption?: string } = {};
    if (props.editOptions.allowResize) {
        fields.push({ name: "size", label: image.dimension === GraphicDimensionType.height ? "Height" : "Width" + " in pixels", type: FormFieldType.int, fixedWidth: 100 });
        initialValues.size = image.size;
    }
    if (props.editOptions.allowFloat) {
        fields.push({
            name: "float", label: "Wrap", type: FormFieldType.combo,
            comboSource: [{ caption: "None", value: GraphicFloatType.none }, { caption: "Right", value: GraphicFloatType.right }, { caption: "Left", value: GraphicFloatType.left }]
        });
    }
    if (props.editOptions.verifyUrlApiUrl) {
        fields.push({ name: "link", label: "Link", fixedWidth: 350 });
        initialValues.link = image.url;
    }
    if (props.editOptions.captions === CaptionOptionsEnum.allow && props.editOptions.captionFormat === CaptionFormatEnum.plain) {
        fields.push({ name: "caption", label: "Caption", type: FormFieldType.multiLine, inputHeight: 300 });
        initialValues.caption = image.caption;
    }

    return (
        <EditAttributesContainer>
            <TitleText>Edit Image Attributes</TitleText>
            <ButtonsRow>
                {image.file ? (
                    <IconButton caption="Save and upload" icon="fas fa-upload" onClick={onSubmit} />
                ) : (
                    <IconButton caption="Save" icon="fas fa-save" onClick={onSubmit} />
                )}
                <IconButton style={{ marginLeft: "8px" }} caption="Cancel" onClick={() => props.onSubmit(null)} />
            </ButtonsRow>
            <StyledImageBox>
                <StyledImage src={formatImageOrVideoSrcWithFile(image, props.fileOptions)} />
            </StyledImageBox>
            {fields.length > 0 &&
                <SamForm id="attributes" forms={forms} fields={fields} initialValues={initialValues} onChange={formChanged} />
            }
            {props.editOptions.captions === CaptionOptionsEnum.allow && props.editOptions.captionFormat === CaptionFormatEnum.html &&
                <SamTextEditor id="infoText" title="Edit Caption" html={image.caption ? image.caption : ''} textHeight={300} textWidth={500}
                    setEditor={editor => setCaptionEditor(editor)} />
            }
        </EditAttributesContainer>
    )
}
//-------------------------------------------------------------
interface ConfirmDeleteDlgProps {
    filename: string;
    onSubmit: (confirmed: boolean) => void;
}
const ConfirmDeleteDlg: React.FC<ConfirmDeleteDlgProps> = (props) => {
    const handleSubmit = (modalId?: string, buttonId?: string) => {
        props.onSubmit(buttonId === "confirm");
    }
    const buttonStyle = { width: "60px" };
    return (
        <SamModal text={"Are you sure you want to remove " + props.filename + "?"} onSubmit={handleSubmit}
            submitButtons={[
                { style: buttonStyle, id: "confirm", caption: "Yes" },
                { style: { ...buttonStyle, marginLeft: "16px" }, id: "cancel", caption: "No" }
            ]} />
    )
}
//-------------------------------------------------------------
const ImageContainer = styled.div<{ width?: string; height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width}; 
    height: ${props => props.height};
`
const Image = styled.img<{ width: string; height: string }>`
    max-width: ${props => props.width};
    max-height: ${props => props.height};
    width: auto;
    height: auto;
    cursor: pointer;
`
interface ILHImageProps {
    id: string;
    index: number;
    image: ImageRecord;
    size: number;
    sizeConstrained: boolean;           // if true image is constrained to square of dimension size; else the prominent dimension is sized and other dimension is auto
    attributesPosition: AttributesPosition;
    fileOptions: ImageFileOptions;           // folder under ".../graphics" (e.g. "carousel")
    reorgImages: (source: number, target: number) => void;          // move target index to source index and renumber
    insertImage: (image: ImageRecord, index: number) => void;   // new image from file system; index=-1 to add to end
}
const ILHImage: React.FC<ILHImageProps> = (props) => {
    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;


    //-------- DRAG AND DROP --------------
    const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
        const id = getNearestParentId(e.target as HTMLImageElement).id;
        e.dataTransfer.setData(ImageDragType, id);
    }
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if (e.dataTransfer.items[i].type === ImageDragType) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const target = parseInt(getNearestParentId(e.target as HTMLImageElement).id);
        if (e.dataTransfer.files.length) {
            // dropping from file system
            if (e.dataTransfer.files.length > 1) {
                alert("Please drag only one photo at a time");
            } else {
                props.insertImage({ filename: e.dataTransfer.files[0].name, file: e.dataTransfer.files[0], rowState: RowState.added }, target);
            }
        } else {
            props.reorgImages(parseInt(e.dataTransfer.getData(ImageDragType)), target);
        }
    }
    //-------- END DRAG AND DROP --------------

    let imageWidth = props.size + "px";
    let imageHeight = props.size + "px";
    if (!props.sizeConstrained) {
        if (props.attributesPosition === AttributesPosition.right) {
            imageHeight = "auto";
        } else {
            imageWidth = "auto";
        }
    }
    let imageContainerWidth: string | undefined = undefined;
    let imageContainerHeight: string | undefined = undefined;
    if (props.sizeConstrained) {
        if (props.attributesPosition === AttributesPosition.right) {
            imageContainerHeight = "100%";
            imageContainerWidth = "auto";
            //        imageContainerWidth = props.size + "px";
        } else {
            imageContainerWidth = "100%";
            imageContainerHeight = "auto";
            //        imageContainerHeight = props.size + "px";
        }
    }
    return (
        <ImageContainer width={imageContainerWidth} height={imageContainerHeight}>
            <Image width={imageWidth} height={imageHeight} id={props.index + ''} src={formatImageOrVideoSrcWithFile(props.image, props.fileOptions)}
                onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleDrop} />
        </ImageContainer>
    )
}
//--------------------------------------------------------
const ImageInputContainer = styled.div`
    display: flex;
`
// square container holds image "chooser" and video "chooser"
const ImageVideoChooserContainer = styled.div<{ size: number }>`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: ${props => props.size}px;
    justify-content: center;
`
// width is props.size; height is half that if videos allowed
const ImageChooserContainer = styled.div<{ width: number }>`
    width: 100%;
    height: 100px;
    border: 1px #ccc solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
        margin-left: 8px;
        margin-right: 8px;
    }
`
const VideoChooserContainer = styled.div`
    width 100%;
`
export enum ImageInputFlags { allowImage = 1, allowVideo = 2, allowDragFromFileSystem = 4, allowCaption = 8 }
interface ImageInputProps {
    flags: ImageInputFlags;     // image or video or both must be allowed
    showCancelButton?: boolean;
    existingImage?: ImageRecord
    size?: number;       // for display, NOT uploading (see fileOptions for uploading); default to fileOptions
    dimension?: GraphicDimensionType;    // ditto
    fileOptions: ImageFileOptions;
    uploadOptions?: ImageUploadOptions;
    onImageSelected: (image: ImageRecord | null) => void;
    // to set data using the following, use e.dataTransfer.setData(ImageDragType, id)
    onImageDropped?: (data: string) => void;       // if this is passed, items can be dropped if they have a type of "imagelist"; the data is the dataTransfer.item.data field
}
const ImageInput: React.FC<ImageInputProps> = (props) => {
    const [imageToUpload, setImageToUpload] = React.useState<File>();

    const { post } = usePostApi();
    const { getToken } = useTokens();

    const displaySize = props.size ? props.size : props.fileOptions.size;
    const displayDimension = props.dimension ? props.dimension : props.fileOptions.dimension;

    const imageUploaded = (filename: string) => {
        const newImage = { ...imageToUpload, filename, file: undefined };
        setImageToUpload(undefined);
        props.onImageSelected(newImage);
    }

    console.log("ImageInput fileOptions:", props.fileOptions)

    React.useEffect(() => {
        if (imageToUpload) {
            const form = new FormData();
            form.append("file", imageToUpload);
            form.append('size', (props.fileOptions.size ? props.fileOptions.size : 500) + '');
            form.append('dimension', props.fileOptions.dimension ? props.fileOptions.dimension : GraphicDimensionType.width);
            form.append('domain', props.uploadOptions!.targetDomain);
            form.append('outputMagnified', props.fileOptions.sizeDesignator ? 'Y' : 'N');
            if (props.fileOptions && props.fileOptions.graphicsSubfolder) {
                form.append('graphics_subfolder', props.fileOptions.graphicsSubfolder);
            }
            setImageToUpload(undefined);
            console.log("posting form:",
                {
                    filename: imageToUpload.name, 
                    size: props.fileOptions.size ? props.fileOptions.size : 500,
                    dimension: props.fileOptions.dimension ? props.fileOptions.dimension : GraphicDimensionType.width, 
                    domain: props.uploadOptions!.targetDomain, graphics_subfolder: props.fileOptions.graphicsSubfolder,
                    outputMagnified: props.fileOptions && props.fileOptions.sizeDesignator ? 'Y' : 'N'
                });
            post(props.uploadOptions!.uploadImageApiUrl, form, imageUploaded, () => alert("Server error trying to upload image"), getToken()!.token, true);
        }
    }, [imageToUpload]);

    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
    const allowImage = (props.flags & ImageInputFlags.allowImage) > 0;
    const allowVideo = (props.flags & ImageInputFlags.allowVideo) > 0;
    const allowDragFromFileSystem = (props.flags & ImageInputFlags.allowDragFromFileSystem) > 0;

    //-------- DRAG AND DROP ------------
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if ((allowDragFromFileSystem && e.dataTransfer.items[i].type.startsWith("image/")) || (props.onImageDropped && e.dataTransfer.items[i].type === ImageDragType)) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.files.length) {
            // dropping from file system
            if (e.dataTransfer.files.length > 1) {
                alert("Please drag only one photo at a time");
            } else {
                setImageToUpload(e.dataTransfer.files[0]);
            }
        } else if (e.dataTransfer.getData(ImageDragType) !== '') {
            if (props.onImageDropped) {
                props.onImageDropped(e.dataTransfer.getData(ImageDragType));
            }
        }
    }
    //-------- END DRAG AND DROP ------------

    //-------- BROWSE FOR FILE ------------
    const browseClicked = (e: React.MouseEvent<HTMLElement>) => {
        fileInputRef.current.click();
    }
    const handleFileChosen = () => {
        //    console.log("got file: " + fileInputRef.current.files[0].name);
        const elem = fileInputRef.current;
        if (!isImageFile(elem.files![0])) {
            alert("File must be an image (.jpg .png .gif)");
        } else {
            setImageToUpload(elem.files![0]);
        }
    }
    //-------- END BROWSE FOR FILE ------------

    let imageWidth = props.size + "px";
    let imageHeight = imageWidth;
    if (displayDimension === GraphicDimensionType.height) {
        imageWidth = "auto";
    } else {
        imageHeight = "auto";
    }
    return (
        <ImageInputContainer>
            {props.existingImage &&
                <ImageContainer>
                    <Image width={imageWidth} height={imageHeight} src={formatImageOrVideoSrcWithFile(props.existingImage, props.fileOptions)}
                        onDragOver={handleDragOver} onDrop={handleDrop} />
                </ImageContainer>
            }
            <ImageVideoChooserContainer size={displaySize ? displaySize : 200} onDragOver={handleDragOver} onDrop={handleDrop}>
                {allowImage &&
                    <ImageChooserContainer width={displaySize ? displaySize : 200} onClick={browseClicked}>
                        <p>Drop new image here or click to browse for file</p>
                    </ImageChooserContainer>
                }
                {allowVideo &&
                    <VideoChooserContainer>
                        <AddVideoBox videoAdded={props.onImageSelected} />
                    </VideoChooserContainer>
                }
                {props.showCancelButton &&
                    <ButtonsRow>
                        <IconButton style={{ marginTop: "16px" }} caption="Cancel" icon="fas fa-ban" onClick={() => props.onImageSelected(null)} />
                    </ButtonsRow>
                }
            </ImageVideoChooserContainer>
            <input style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChosen} type="file" />
        </ImageInputContainer>
    )
}
const AddVideoBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px #ccc solid;
    width: 100%;
`
const AddVideoInput = styled.textarea`
    margin-left: 3%;
    width: 90%;
    border: none;
    height: 90px;
`
interface AddVideoBoxProps {
    videoAdded: (image: ImageRecord) => void;
}
const AddVideoBox: React.FC<AddVideoBoxProps> = (props) => {
    const videoInputRef = React.useRef<HTMLTextAreaElement>() as React.MutableRefObject<HTMLTextAreaElement>;

    const parseYoutubeId = (text: string): string => {
        // text could be formatted as: "https://youtu.be/ts3s738ZkcQ" 
        // or '<iframe width="1038" height="584" src="https://www.youtube.com/embed/cKsTJtEClpw" frameborder="0" allow="accelerometer; ... etc. etc.'
        let posn = text.indexOf('embed/');
        if (posn >= 0) {
            posn += 6;
        } else {
            posn = text.indexOf('youtu.be/');
            if (posn >= 0) {
                posn += 9;
            }
        }
        //       console.log("posn=" + posn);
        if (posn === -1) {
            return '';
        }
        let id = '';
        for (let i = posn; i < text.length && text[i] !== ' ' && text[i] != '"'; i++) {
            id += text[i];
        }
        return id;
    }

    const saveClicked = () => {
        //   console.log("got video: " + videoInputRef.current.value);
        if (!videoInputRef.current.value) {
            alert('Please enter the YouTube "share" or "embed" text');
        } else {
            const youtubeId = parseYoutubeId(videoInputRef.current.value);
            if (!youtubeId) {
                alert('Could not find YouTube video id. Embed text should include "youtu.be/nnnnnn" or "embed/nnnnn"');
            } else {
                videoInputRef.current.value = '';
                props.videoAdded({ youtube_id: youtubeId });
            }
        }
    }
    return (
        <AddVideoBoxContainer>
            <AddVideoInput ref={videoInputRef} placeholder='Find video on YouTube, click "share" or "embed", copy and paste the provided text, then click "Verify video" to add' />
            <ButtonsRow>
                <IconButton style={{ fontSize: "12px", height: "26px", marginBottom: "8px" }} caption="Verify video" icon="fas fa-video" onClick={saveClicked} />
            </ButtonsRow>
        </AddVideoBoxContainer>
    )
}

export default ImageListHandler;


/* the following is deprecated; images are now uploaded upon selection
//---------- UPLOAD SUPPORT ----------------
// return true if there is at least one image to upload; if so use UploadImages
export const anyImagesToUpload = (images: ImageRecord[]): boolean => {
    return images.some(image => image.file);
}
// same as above but pushes the images onto an array
export const pushImagesToUpload = (images: ImageRecord[], toUpload: ImageRecord[]) => {
    images.forEach(image => {
        if (image.file) {
            toUpload.push({ ...image });
        }
    });
}

// the following displays a modal with upload progress, calls uploadComplete() when done
// only images with valid file property are uploaded
interface UploadImagesProps {
    images: ImageRecord[];
    size: number;
    dimension: GraphicDimensionType;
    targetDomain: string;
    graphicsSubfolder?: string;
    uploadImageApiUrl: string;
    outputMagnified?: boolean;      // if true the image will be uploaded as _f and _m versions (_m is double resolution)
    imageChanged: (index: number, newImage: ImageRecord) => void;   // required so corrected filename can be set
    uploadComplete: (status: boolean, message: string) => void;      // status is true on success
}

export const UploadImages: React.FC<UploadImagesProps> = (props) => {
    const [uploadedFiles, setUploadedFiles] = React.useState<string[]>([]);
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [index, setIndex] = React.useState<number>(-1);

    const { getToken } = useTokens();
    const { post } = usePostApi();

    React.useEffect(() => {
        setIndex(state => state + 1);
    }, []);

    const addUploading = (filename: string) => {
        const newFiles = uploadedFiles.map(file => file);
        newFiles.push("Uploading " + filename);
        setUploadedFiles(newFiles);
    }

    const imageUploaded = (filename: string) => {
        props.imageChanged(index, { ...props.images[index], filename });
        setIndex(state => state + 1);
    }

    React.useEffect(() => {
        if (index < props.images.length) {
            if (index >= 0) {
                const image = props.images[index!];
                if (image.file) {
                    addUploading(image.file.name);
                    const form = new FormData();
                    form.append("file", image.file);
                    form.append('size', props.size + '');
                    form.append('dimension', props.dimension);
                    form.append('domain', props.targetDomain);
                    form.append('outputMagnified', props.outputMagnified ? 'Y' : 'N');
                    if (props.graphicsSubfolder) {
                        form.append('graphics_subfolder', props.graphicsSubfolder);
                    }
                    //      console.log("posting form:", { file: image.file, size: props.size, dimension: props.dimension, domain: props.targetDomain, graphics_subfolder: props.graphicsSubfolder });
                    post(props.uploadImageApiUrl, form, imageUploaded, (error: string) => setErrorMsg(error), getToken()!.token, true);
                }
                else {
                    setIndex(state => state + 1);
                }
            }
        } else {
            props.uploadComplete(true, "Uploaded " + uploadedFiles.length + " images");
        }
    }, [index]);

    React.useEffect(() => {
        if (errorMsg) {
            props.uploadComplete(false, errorMsg!);
        }
    }, [errorMsg]);

    return (
        <StatusModal statusList={uploadedFiles} />
    )
}
*/
