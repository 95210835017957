import { DivisionEnum } from "./interfaces/lib-api-interfaces";

const getInfoContent = "/api/getInfoContent/" // add page url OR section code to get index of section
const setInfoContent = "/api/setInfoContent";
const dashboardLogin = "/api/dashboardLogin/fernsgarden";
const getProductCategories = "/api/categoriesAsArray/" + DivisionEnum.jmc;
const setProductCategory = "/api/setProductCategory";
const getProducts = "/api/products/" + DivisionEnum.jmc + "/";   // + category;
const search = "/api/wsSearch";
const createIndex = "/api/fgCreateIndex";

const uploadImage = "/api/uploadImage";

export default {
    getInfoContent,
    setInfoContent,
    getProductCategories,
    setProductCategory,
    dashboardLogin,
    getProducts,
    search,
    createIndex,

    uploadImage,

}