import React from 'react';
import styled from 'styled-components';

import { VoidCallback } from '../interfaces/lib-react-interfaces';

import app from '../appData';

/* example usage:
    const [contextMenuInfo, setContextMenuInfo] = React.useState();
    in component to handle right click: onContextMenu={contextMenuInvoked} (this is a React event)
        const contextMenuInvoked = e => {
        let contextMenu = [];
        if (props.allowInsert) {
            contextMenu.push(new ContextMenuItem("Insert new row here", handleInsertRow, row));
            contextMenu.push(new ContextMenuItem("Add row to end", handleInsertRow, -1));
        }
        if (contextMenu.length) {
            e.preventDefault();
            setContextMenuInfo(new ContextMenuInfo({ x: e.clientX, y: e.clientY }, contextMenu));
        }
    }
    in render:
        {contextMenuInfo && <ContextMenu info={contextMenuInfo} closePopup={() => setContextMenuInfo(null)} />}
*/

export class ContextMenuItem {
    caption: string;
    callback: (userData: any) => void;
    userData: any;
    constructor(caption: string, callback: (userData: any) => void, userData: any) {
        this.caption = caption;
        this.callback = callback;       // userData is passed to callback
        this.userData = userData;
    }
}

export class ContextMenuInfo {
    location: { x: number; y: number };
    menu: ContextMenuItem[];
    constructor(location: { x: number; y: number }, menu: ContextMenuItem[]) {
        this.location = location;
        this.menu = menu;
    }
}

const ContextMenuContainer = styled.div<{ left: number; top: number; backColor: string }>`
    z-index: 2000;
    position: fixed;
    left: ${props => props.left}px;
    top: ${props => props.top}px;
    display: flex;
    flex-direction: column;
    button {
        height: 24px;
        font-size: 14px;
        background-color: ${props => props.backColor};
        color: white;
    }
`
/* props:
    info: ContextMenuInfo
    closePopup() -- callback used when cursor leaves menu
*/
interface ContextMenuProps {
    info: ContextMenuInfo;
    closePopup: VoidCallback;
}
const ContextMenu: React.FC<ContextMenuProps> = (props) => {
    const handleLeave = () => props.closePopup();
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const item = props.info.menu[parseInt(target.id)];
        item.callback(item.userData);
    }
    return (
        <ContextMenuContainer backColor={app.themes.foreColor} left={props.info.location.x} top={props.info.location.y} onMouseLeave={handleLeave}>
            {props.info.menu.map((item, index) => {
                return (
                    <button key={item.caption} id={index + ''} onClick={handleClick}>{item.caption}</button>
                )
            })}
        </ContextMenuContainer>
    )
}
export default ContextMenu;

