/* login/register status codes (return success but not registered or logged in):
    ALL:
        201: customer, zip or email not found (WS.register, WS.login, RTL.login)
        202: invalid password (RTL.login, RTL.register, WS.login)
        203: need to register in new system (RTL.login)
*/
export enum RowState { unchanged = 1, added = 2, modified = 4, deleted = 8 };
export enum LoginError { custNotFound = 201, invalidPassword = 202 }
export enum CreditCardType { none = "N", amex = "3", visa = "4", masterCard = "5", discover = "6" }
export enum DivisionEnum { jmc = 'J', htm = 'H', fg = 'F', none = 'N', belmont = 'B', berkeley = 'S', wholesale = 'W' }
export enum GraphicDimensionType { width = 'w', height = 'h', longest = 'l' }
export enum GraphicFloatType { none = 'N', right = 'R', left = 'L' }
export enum ImageSizeEnum { full = 'f', magnified = 'm' };
export enum VerifyUrlResult { valid = 200, invalid = 201 }
export enum CouponStatus { valid = 200, invalidCode = 202, expired = 203 }

/* userData can contain a visible tag as follows:
        "rtlOnly" -- visible if visitor not logged in
        "wsOnly" -- visible only if visitor is logged in
        "adminOnly" -- visible only if visitor is logged in as admin
    these are handled by MasterPage and not directly by NavBar
*/
export interface TokenRecord {
    userId: number | string,    // custId for WS or retail, domain for web site admin
    token: string
}

export class MenuItemData {
    caption: string;
    href: string | null;            // can be null if popupItems are given
    popupItems?: MenuItemData[];   // not on popupItems or SiteMapData
    userData?: any;                 // not on popupItems or SiteMapData
    categoryId?: number;            // used by dashboard
    constructor(caption: string, href: string | null, popupItems?: MenuItemData[], userData?: any, categoryId = 0) {
        this.caption = caption;
        this.href = href;
        this.popupItems = popupItems;
        this.userData = userData;
        this.categoryId = categoryId;
    }
}
export const ImageDragType = "imagelist";

export interface BuyBoxProductRecord {
    bullet_points?: string;
    images: string[];
    caption: string;
    videos?: ImageRecord[];
}

// basic all purpose image record for dashboard and app
export interface ImageRecord {
    caption?: string;
    filename?: string;
    youtube_id?: string;
    url?: string;       // link
    tag?: string;       // displayed in bold (useful for SKU #)
    file?: File;            // valid if image has been dragged or chosen from local filesystem
    display_order?: number;
    size?: number; 
    dimension?: GraphicDimensionType; 
    float?: GraphicFloatType;   // currently used only for main image in info pages (about us needs to be wrapped right)
    rowState?: RowState;    // for ImageListHandler; caller should pass this as unchanged
    size_pct?: number;      // percentage to display image in apps; used for small items in fernsgarden.com
    embed_text?: string;    // youtube output text; for editing videos in dashboard
    alt?: string;
}
// following used to display images, also for uploading
export interface ImageFileOptions {
    graphicsSubfolder?: string;      // folder under "graphics": "other", "blog", etc.
    sizeDesignator?: ImageSizeEnum;     // full or magnified if image is stored in 2 versions
    size?: number;                   // default; overridden by size prop in ImageRecord; default to 500
    dimension?: GraphicDimensionType;    // ditto; default to width
}
// following used when editing images, applies to all images in a list
export interface ImageDisplayRecord {
    max_items: number | null;
    size: number;
    dimension: GraphicDimensionType;    // determines which dimension size applies to; other dimension is auto
    is_fixed_size: boolean;
    graphics_subfolder?: string;
    allow_videos: boolean;
    allow_captions: boolean;
    allow_filesystem: boolean;      // for choosing images; true to allow user to drag image from file system
}


// following is stored in userData field of MenuItemData for category editor
export interface CategoryEditorUserDataRecord {
    categoryId: number;
    isExpanded: boolean;
    isChecked: boolean;
    isIndented: boolean;        // in this one there is only one level of indentation
    parentId: number;              // applies only to popup items
    displayOrder: number;       // from table; for inserting new categories
    subcategoryChecked: boolean;    // used only inside category editor
}
// use alpha values since this can be stored in website content table (type=record)
// BCDFIJLMNOPRSTUVX$
export enum FormFieldType { 
    // all uses
    // multiLine should specify inputHeight in field or it will be one line tall
    text = 'T', multiLine = 'U', combo = 'C', link = 'L', checkbox = 'X', button = 'B', 
    // masked
    phone = 'P', date = 'D', 
    // /specialized input
    reactPaymentInputs = 'V',   // must pass getProps to SamForm
     digitsOnly = 'N',          // requires length to be in field.validator.maxLength
    // numeric
    int = 'I', money = '$', fixedPoint = 'F',
    // grids only
    image = 'J', selector = 'S', radioList = 'R', staticImage = 'M', icon = 'O' }; 
export enum FormFieldAlignType { left = 'L', center = 'C', right = 'R' }
export interface FormFieldButtonRecord {
    caption: string;
    icon?: string;
    style?: Record<string, any>;
}
export interface FormFieldRecord {
    name: string;
    label?: string;         // could be omitted on grid with no captions
    type?: FormFieldType;       // default to text
    initialValue?: any;
    placeholder?: string;
    inputHeight?: number;   // height of input field in pixels (applies to text and numbers only, and is height for multiline)
    marginTop?: number;    // space between this field and the one above it
    validator?: ValidatorRecord;
    comboSource?: FormComboFieldRecord[];
    width?: number;         // as a percentage
    fixedWidth?: number;    // in pixels
    button?: FormFieldButtonRecord;
    onClick?: (id: string) => void;
    hideField?: (values: Record<string, any>) => boolean;
    visible?: boolean;              // default to true
    getProps?: (options: Record<string, any>) => Record<string, any>;

    // grid use only:
    allowEditing?: boolean;
    align?: FormFieldAlignType;     // default to center
    staticImageUrl?: string;    // required on field type staticImage
    icon?: string;              // required on field type icon
    iconFontSize?: number;      // icon usually needs to be in bigger font
    cursor?: string;            // usually "pointer" or omit
}
export interface FormComboFieldRecord {
    caption: string;
    value: string;
}
export interface ValidatorRecord {
    required?: boolean;
    maxLength?: number;     // REQUIRED if FormFieldType is digitsOnly
    minLength?: number;

    isAllUpper?: boolean; // (handled at keystroke level; no errors)
    isAllNumeric?: boolean;     // ditto
    isPassword?: boolean; // (shows stars instead of chars)
    min?: number; // (numeric only)
    max?: number; // (numeric only)

}
// return null if valid, error if not valid
export interface CustomValidatorCallback {
    (fieldName: string, values: Record<string, any>): string | null;
}
export interface CustomFormatterCallback {
    (fieldName: string, value: string): string;
}

export interface TotalsRecord {
    inv_net: number;        // money
    nontaxable: number;        // money  (total of gift certificates purchased)
    discount: number;        // money 
    sales_tax: number;        // money 
    freight: number;        // money
    voucher_applied: number;        // money
    reward_applied: number;        // money
    inv_tot: number;        // money 
}

export enum AddressType { ship = 'S', bill = 'B', all = 'A' };
export interface AddressRecord {
    email: string;
    fname?: string;     // fname and lname for fg only
    lname?: string;
    contact_name?: string;  // ws only
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    country: string;
    address_id: number;
    storefront?: boolean;
    res_del?: boolean;
    is_default?: boolean;
    [key: string]: any
}
export interface AddressRecordWithPassword extends AddressRecord {
    password: string;
}
export interface CreditCardRecord {
    cardNumber?: string;
    expiryDate?: string;         // mmyy or mm/yy
    cvc?: string;            // 3 or 4 digits
    zip?: string;            // 5 digits
    cardHolder?: string;
    saveCard?: boolean;
}
export interface CreditCardSummaryRecord {
    last4: string,
    card_type: CreditCardType,
    cardTypeDesc: string
}
export interface SchemaRow {
    column_name: string,
    data_type: string,      // note, this is verbose type; must be converted to sqlTypes for use in ParameterRecord
    max_length: number,
    isPrimaryKey?: boolean,
    isNullable: boolean
}
// for passing to text editor, and saving blog content
export interface TextOrImageRecord {
    seq_num?: number;           // for dashboard
    content_type: InfoContentTypeEnum;
    text?: string;          // required for text content
    image?: ImageRecord;    // required for image content
    // url can have one of 3 formats: "https://..." is an external link; "part1/part2..." is an internal link; "video://YOUTUBE-ID" is a video link
    url?: string;           // required for link content (text is the underlined part, url is the href part, caption is urlencoded and appended to url after slash)
}
//-------- BLOG RECORDS ---------------
export interface BlogIndexRecord {
    blog_id: number;
    domain: string;         // domain+tag guaranteed unique, can be used instead of blog_id to access data
    tag?: string;           // used if multiple blogs in a single domain
    title: string;          // e.g.: "BLM Project" or "Good to Know" -- BlogEntryRecords correspond to BlogIndexRecord
    approver_email: string;
}
export interface BlogEntryRecord {
    blog_id: number;
    entry_id: number;       // #1 contains the optional pinned entry at the top of the blog; can just be a logo or maybe short description with photos
    title: string;          // e.g.: "A Quilt of Many Colors" -- TextOrImageRecords all correspond to one BlogEntryRecord
    post_date: string;
    buy_now_url?: string;
}
export interface BlogCommentRecord {
    entry_id: number;       // comments correspond to one entry
    comment_id: number;
    post_date: string;
    author: string;          // corresponds to handle field
    text: string;
    is_pending: boolean;    // true when approver has approved
}
export interface BlogSubscriberRecord {
    blog_id: number;
    handle: string;
    fname: string;
    lname: string;
    email: string;
}

//-------- INFO RECORDS ---------------
export enum InfoContentTypeEnum { text = 'T', newParagraph = 'P', link = 'L', image = 'I' }
export enum InfoSectionTypeEnum { artists = 'A', info = 'I' }       // someday product info or whatever or "Good To Know"
export interface InfoIndexRecord {
    division: DivisionEnum;     // fg or wholesale
    info_id: number;
    url: string;
    graphics_subfolder: string;
    title: string;
    subtitle?: string;
    section: InfoSectionTypeEnum;
    image?: ImageRecord;
    display_order: number;         // only needed for dashboard
    summary: string;
    isLive?: boolean;       // for dashboard only
}
// returned by get content api
export interface InfoContentRecord {
    indexRecord: InfoIndexRecord;
    contents: TextOrImageRecord[];
}
export interface SetInfoDisplayOrderRecord {
    info_id: number;
    display_order: number;
}
