import React from 'react';
import { SamProvider } from './libs/SamState';

import Routes from './Routes';

export default function App() {
  return (
    <SamProvider>
      <Routes />
    </SamProvider>
  );
}
