import React from 'react';
import styled from 'styled-components';

const StyledBar = styled.i<{ height: number }>`
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid ;
    font-size: ${props => props.height + 4}px;
    height: ${props => props.height}px;
    width: ${props => props.height - 8}px;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
`
interface CloseDialogBarProps {
    height?: number;        // default to 32
    onClose: () => void;
}
const CloseDialogBar: React.FC<CloseDialogBarProps> = (props) => {
    return (
        <StyledBar className="fas fa-times" height={props.height ? props.height : 32} onClick={props.onClose} />
    )
}
export default CloseDialogBar;
