import React from "react";
import deepEqual from 'deep-equal';
import Cookie from 'universal-cookie';
import { History } from 'history';

import { TokenRecord } from "../interfaces/lib-api-interfaces";

import app from '../appData';

const SamContext = React.createContext<{
    state: Record<string, any>;
    dispatch: React.Dispatch<any>;
  }>({
    state: {},
    dispatch: () => null
  });
//export const SamContext = React.createContext({});

interface Action {
    type: string;
    payload: any;
}
interface SamProviderProps {
    children: React.ReactChildren;
}
export const SamProvider: React.FC<SamProviderProps> = (props) => {
    const reducer = (state: Record<string, any>, action: Action) => {
        // if (!Object.keys(app.initialState).includes(action.type)) {
        //     return state;
        // }
        if (!deepEqual(state[action.type], action.payload)) {
            //          console.log("action.type=" + action.type + ", existing state:"); console.log(state[action.type]); console.log("new state:"); console.log(action.payload);
            let newState = { ...state };
            newState[action.type] = action.payload;
            //          console.log("newState:"); console.log(newState);
            return newState;
        } else {
            return state;
        }
    };
    const [state, dispatch] = React.useReducer(reducer, {});
    const value = { state, dispatch };
    return (
        <SamContext.Provider value={value}>{props.children}</SamContext.Provider>
    );
}
export const SamConsumer = SamContext.Consumer;

export const useSessionStore = () => {
    const { getContext, setContext } = useGlobalContext();
    // following transfers session storage to component storage; can also be used to fetch if not in session storage
    // key : string, data : object, setData : callback to set component storage, fetchInfo { url : string, token : optional string, isFetchLoading and fetch are callbacks into useDataApi }
    // if setData is omitted the data is only returned; setData is required if fetch info given
    // data can be passed as shortcut to placing it in component storage; if not null and setData given it will be set
    // token will be defaulted if not passed
    const getSessionStore = (key: string): any => {
        const rawData: string | null = sessionStorage.getItem(key);
        return rawData ? JSON.parse(rawData) : null;
    }
    const sessionStoreContains = (key: string) => {
        return (key in sessionStorage);
    }
    const setSessionStore = (key: string, data: any) => {
    //    console.log("setting " + key + " as", data)
        sessionStorage.setItem(key, JSON.stringify(data));
        const forceRerender: number | undefined = getContext("forceRerender");
        setContext("forceRerender",  Date.now());
    }
    const clearSessionStore = () => {
        sessionStorage.clear();
    }
    const deleteSessionStore = (key: string) => {
        sessionStorage.removeItem(key);
    }
    return { getSessionStore, setSessionStore, clearSessionStore, deleteSessionStore, sessionStoreContains }
}
export const useGlobalContext = () => {
    const { dispatch, state } = React.useContext(SamContext);
    const getContext = (key: string) => {
        return state[key];
    }
    const setContext = (key: string, data: any) => {
        dispatch({ type: key, payload: data });
    }
    return { getContext, setContext }
}

export const useCookies = () => {
    const areCookiesEnabled = () => {
        let cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled) {
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
        }
        return cookieEnabled;
    }
    const setCookie = (key: string, value: any, expDays: number) => {
        const cookie = new Cookie();
        cookie.set(key, value, { domain: window.location.hostname, path: "/", expires: addDays(new Date(), expDays) });
    }
    const getCookie = (key: string) => {
        const cookie = new Cookie();
        return cookie.get(key);
    }
    const removeCookie = (key: string) => {
        const cookie = new Cookie();
        cookie.remove(key, { path: "/", domain: window.location.hostname });
    }
    return { areCookiesEnabled, getCookie, setCookie, removeCookie };
}

export const useBookmarks = () => {
    const { getSessionStore, setSessionStore } = useSessionStore();

    // pass url null to use current url (always use window.location.pathname)
    const setBookmark = (url: string | null) => {
        setSessionStore("bookmark", url ? url : window.location.pathname);
    }
    const navigateToBookmark = (history: History) => {
        let url = getSessionStore("bookmark");
        setSessionStore("bookmark", null);
        if (url === "/login" || url === "/register")
            url = "/";
        window.location.href = url ? url : "/";
    }
    return { setBookmark, navigateToBookmark };
}

export const useTokens = () => {
    const { getCookie, setCookie, removeCookie } = useCookies();
    const { getSessionStore, setSessionStore, deleteSessionStore } = useSessionStore();
    const { setContext } = useGlobalContext();

    // pass persist=true to store in cookie, else store in session state
    const setToken = (token: TokenRecord, persist = true) => {
        if (persist) {
            setCookie("token", token, 30);
        } else {
            setSessionStore("token", token);
        }
    }
    // check session store first, then cookie (token will be in cookie if user checked "remember me")
    const getToken = (): TokenRecord | null => {
        let token = getSessionStore("token");
        if (token) {
            return token;
        }
        return getCookie("token");
    }
    const clearToken = () => {
        removeCookie("token");
        deleteSessionStore("token");
        setContext("rerender", Date.now());
    }
    return { setToken, getToken, clearToken };
}

export const addDays = (date: Date, days: number) => {
    return new Date(date.setDate(date.getDate() + days));
}

