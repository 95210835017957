import React from 'react';
import styled from 'styled-components';
import { Rifm } from 'rifm';
import { AsYouType } from 'libphonenumber-js';

import { FormFieldType } from '../../interfaces/lib-api-interfaces';

const StyledChevs = styled.div<{ fontSize: number; width: number; height: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: lightgray;
    font-size: ${props => props.fontSize / 2}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    align-items: center;
`
const StyledChev = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50%;
    cursor: pointer;
`
const StyledInput = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`

interface RifmNumericProps {
  fieldType: FormFieldType;      // int = 'I', money = '$', fixedPoint, phone
  name: string;
  initialValue?: string | number;   // string for phone, else number
  fontSize?: number;     // defaults to 14
  width?: number;     // omit for 100%
  height?: number;      // default to 28
  allowNegative?: boolean;
  allowZero?: boolean;
  decimalPlaces?: number;
  showChevrons?: boolean;
  borderColor?: string;
  placeholder?: string;
  textAlign?: string;   // defaults to "right" on numbers, "left" on phone, but could be passed as "center"
  numericLength?: number;   // required for type=digitsOnly
  onChange?: (value: string | number, name: string) => void;    // value is number except phone is string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}
const RifmNumeric: React.FC<RifmNumericProps> = (props) => {
  const [stringValue, setStringValue] = React.useState<string>(props.initialValue ? props.initialValue + '' : '');
  // const [fieldType, setFieldType] = React.useState<FormFieldType>(props.fieldType);
  // const [decimalPlaces, setDecimalPlaces] = React.useState<number>(0);
  //  const [negative, setNegative] = React.useState('12345');
  //  const [fixedFloat, setFixedFloat] = React.useState('12345');

  const inputWidth = props.width ? props.width : "100%";
  const inputHeight = props.height ? props.height : 28;
  const fieldType: FormFieldType = props.fieldType === FormFieldType.money ? FormFieldType.fixedPoint : props.fieldType;
  const decimalPlaces: number = props.fieldType === FormFieldType.money ? 2 : (props.decimalPlaces ? props.decimalPlaces : 0);
  const fontSize: number = props.fontSize ? props.fontSize : 14;
  const chevronWidth: number = props.showChevrons ? fontSize / 2 + 6 : 0;
  const textAlign = props.textAlign ? props.textAlign : (props.fieldType === FormFieldType.phone ? "left" : "right");
  const borderColor = props.borderColor ? props.borderColor : "black";

  const integerAccept = /\d+/g;
  const numberAccept = /[\d.]+/g;
  const negativeAccept = /[\d-]+/g;

  const parseInteger = (value: string) => (value.match(integerAccept) || []).join('');
  const parseNumber = (value: string) => (value.match(numberAccept) || []).join('');
  const parseNegative = (value: string) => (value.match(negativeAccept) || []).join('');

  //--- INTEGERS -----------------------------------
  const formatInteger = (value: string): string => {
    const parsed = parseInteger(value);
    const number = Number.parseInt(parsed, 10);
    if (Number.isNaN(number)) {
      return '';
    }
    return number.toLocaleString('en');
  }

  const formatNegative = (value: string): string => {
    const parsed = parseNegative(value);
    if (parsed === '-') {
      return '-';
    }
    const number = Number.parseInt(parsed, 10);
    if (Number.isNaN(number)) {
      return '';
    }
    return number.toLocaleString('en');
  };

  const handleIntChange = (value: string) => {
    const val = parseInteger(value);
    setStringValue(val);
    const intVal = parseInt(val);
    props.onChange && props.onChange(Number.isNaN(intVal) ? 0 : intVal, props.name);
  }
  const handleNegativeChange = (value: string) => {
    const val = parseNegative(value);
    setStringValue(val);
    const intVal = parseInt(val);
    props.onChange && props.onChange(Number.isNaN(intVal) ? 0 : intVal, props.name);
  }
  const handleIncrementOrDecrement = (amount: number) => {
    let newValInt = parseInt(stringValue);
    if (Number.isNaN(newValInt)) {
      newValInt = 0;
    }
    newValInt += amount;
    if (newValInt === 0 && !props.allowZero) {
      return;
    }
    if (newValInt < 0 && !props.allowNegative) {
      return;
    }
    handleNegativeChange(newValInt + '');
  }
  const handleIncrement = () => {
    handleIncrementOrDecrement(1);
  }
  const handleDecrement = () => {
    handleIncrementOrDecrement(-1);
  }

  //---- FIXED POINT ----------------------------------
  const formatFixedPointNumber = (value: string, digits: number): string => {
    const parsed = parseNumber(value);
    const [head, tail] = parsed.split('.');
    // Avoid rounding errors at toLocaleString as when user enters 1.239 and maxDigits=2 we
    // must not to convert it to 1.24, it must stay 1.23
    const scaledTail = tail != null ? tail.slice(0, digits) : '';

    let number = Number.parseFloat(`${head}.${scaledTail}`);

    // For fixed format numbers deleting "." must be no-op
    // as imagine u have 123.45 then delete "." and get 12345.00 looks bad in UI
    // so we transform here 12345 into 123.45 instead of 12345.00.
    // The main disadvantage of this, that you need carefully check input value
    // that it always has fractional part
    if (digits > 0 && tail == null) {
      const paddedHead = head.padStart(digits + 1 - head.length, '0');
      number = Number.parseFloat(
        `${paddedHead.slice(0, -digits)}.${paddedHead.slice(-digits)}`
      );
    }
    if (Number.isNaN(number)) {
      return '';
    }

    const formatted = number.toLocaleString('en', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });

    return formatted;
  }
  const handleFloatChange = (value: string) => {
    const val = parseNumber(value);
    setStringValue(val);
    const floatVal = parseFloat(val);
    props.onChange && props.onChange(Number.isNaN(floatVal) ? 0 : floatVal, props.name);
  }
  //----- PHONE AND ALLNUMERIC  ---------------------------------
  const parseDigits = (value: string): string => (value.match(/\d+/g) || []).join('');

  const formatPhone = (value: string): string => {
    const digits = parseDigits(value).substr(0, 10);
    return new AsYouType('US').input(digits);
  };
  const handlePhoneOrDigitsChange = (value: string) => {
    setStringValue(value);
    props.onChange && props.onChange(value, props.name);
  }
  //----- ALLNUMERIC ---------------------------------------
  const formatDigits = (value: string): string => {
    const digits = parseDigits(value).substr(0, props.numericLength);
    return digits;
  }
  //--------------------------------------------

  const renderInput = ({ value, onChange }: RenderInputProps) => (
    // type=number is not allowed
    <StyledInput>
      <input
        id={props.name}
        type="tel"
        placeholder={props.placeholder}
        style={{
          textAlign: textAlign as any,
          width: inputWidth,
          height: inputHeight,
          fontSize: fontSize,
          border: "1px solid " + borderColor,
        }}
        value={value}
        onChange={onChange}
        onBlur={props.onBlur}
      />
      {chevronWidth > 0 &&
        <StyledChevs fontSize={fontSize} width={chevronWidth} height={inputHeight}>
          <StyledChev onClick={handleIncrement}>
            <i className="fa fa-chevron-up" />
          </StyledChev>
          <StyledChev onClick={handleDecrement}>
            <i className="fa fa-chevron-down" />
          </StyledChev>
        </StyledChevs>
      }
    </StyledInput>
  );

  if (fieldType === FormFieldType.int) {
    return (
      props.allowNegative ? (
        <Rifm
          accept={/[\d-]/g}
          format={formatNegative}
          value={stringValue}
          onChange={handleNegativeChange}
        >
          {renderInput}
        </Rifm>
      ) : (
        <Rifm
          accept={/\d/g}
          format={formatInteger}
          value={stringValue}
          onChange={handleIntChange}
        >
          {renderInput}
        </Rifm>
      )
    )
  } else if (fieldType === FormFieldType.fixedPoint) {
    return (
      <Rifm
        accept={/[\d.]/g}
        format={v => formatFixedPointNumber(v, decimalPlaces)}
        // 00 is needed here see disadvantages comment at formatNumber
        value={`${stringValue}00`}
        onChange={handleFloatChange}
      >
        {renderInput}
      </Rifm>
    )
  } else if (fieldType === FormFieldType.phone) {
    return (
      <Rifm
        accept={/\d+/g}
        // do not jump after ) until see number before
        mask={
          stringValue.length < 6 && /[^\d]+/.test(stringValue[3])
            ? undefined
            : stringValue.length >= 14
        }
        format={formatPhone}
        value={stringValue}
        onChange={handlePhoneOrDigitsChange}
      >
        {renderInput}
      </Rifm>
    )
  } else if (fieldType === FormFieldType.digitsOnly) {
    return (
    <Rifm
      accept={/\d+/g}
      // do not jump after ) until see number before
      mask={
        stringValue.length < props.numericLength!
          ? undefined
          : stringValue.length >= 14
      }
      format={formatDigits}
      value={stringValue}
      onChange={handlePhoneOrDigitsChange}
    >
      {renderInput}
    </Rifm>
    )
  }
  return null;
}

interface RenderInputProps {
  value: string | string[] | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


export default RifmNumeric;