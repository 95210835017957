import React from 'react';
import styled from 'styled-components';

import WSCategoryEditor from '../libs/dashboard/WSCategoryEditor';

import { DivisionEnum } from '../interfaces/lib-api-interfaces';

const Products: React.FC = () => {
    return (
        <WSCategoryEditor division={DivisionEnum.jmc} />
    )
}
export default Products;